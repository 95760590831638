import React, { useContext } from "react";
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from "react-router-dom";
import authContext from "src/context/AuthContext";
import LoginAppFooter from "src/components/shared/AppFooter/LoginAppFooter";
import AppNavBar from "src/components/shared/AppNavbar/AppNavbar";
import AppFooter from "src/components/shared/AppFooter/AppFooter";
import AppSideBar from "src/components/shared/SideBar/SideBar";

import { LOGIN_PATHS } from "src/utils/constants";
import LayoutStyle from "./LayoutStyle.module.scss";

const CompanyLayout = ({ children, showSideBar }) => {
  const router = useNavigate();
  const location = useLocation();
  const logInPath = LOGIN_PATHS.includes(location.pathname);
  const { logout, currentUser } = useContext(authContext);
  const {
    full_name: itemName, role: itemRole, organization_name: organization, company,
  } = currentUser;
  const itemType = 'company';
  const switchWorkspace = () => {
    router("/workspaces?switch=true");
  };

  return (
    <div>
      {
        logInPath ? (
          <main className={`${LayoutStyle["company-workspace"]}`}>
            {children}
            <LoginAppFooter />
          </main>
        )
          : (
            <>
              <AppNavBar
                loginData={{
                  itemName, itemRole, organization, itemType, company,
                }}
                loggedIn
                logout={logout}
                showCompany
                switchWorkspace={switchWorkspace}
                className={location.pathname === '/' && "landing-navbar"}
              />
              <main className={location.pathname === '/' && "landing-main"}>
                <div className={showSideBar ? 'page-content' : ''}>
                  {showSideBar && <AppSideBar />}
                  {children}
                </div>
              </main>
              <AppFooter />
            </>
          )
      }
    </div>
  );
};

CompanyLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showSideBar: PropTypes.bool.isRequired,
};

export default CompanyLayout;
