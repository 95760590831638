import React from 'react';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ErrorMsgStyle from './ErrorMsgStyle.module.scss';

const FormikErrorMessage = ({ fieldName }) => {
  const { t } = useTranslation('errors');
  const error = (msg) => {
    if (Array.isArray(msg)) {
      return msg.join(',');
    }
    return msg;
  };

  return (
    <ErrorMessage name={fieldName} className={`${ErrorMsgStyle['text-danger']}`}>
      {
        (msg) => (
          <p className="text-danger mt-1">
            {
              msg.key ? t(msg.key, msg.values) : error(msg)
            }
          </p>
        )
      }
    </ErrorMessage>
  );
};

FormikErrorMessage.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default FormikErrorMessage;
