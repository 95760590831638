import { useTranslation } from 'react-i18next';
import {
  faCalendar, faListAlt, faCheckCircle,
} from '@fortawesome/free-regular-svg-icons';
import {
  DASHBOARD_ROLES, SESSION_ROLES, SESSION_MANAGEMENT_ROLES,
  UPCOMING_EXAMS_ROLES, TEST_CYCLE_ROLES,
  PROFILE_ROLES, ATTENDANCE_ROLES,
} from 'src/utils/roles';
import {
  faGaugeHigh, faGear, faList, faPlay,
} from '@fortawesome/free-solid-svg-icons';

export const menuItems = () => {
  const { t } = useTranslation('sidebar');
  const items = [
    {
      id: 1,
      path: '/test-center/dashboard',
      className: 'fa',
      icon: faGaugeHigh,
      title: t("dashboard"),
      isActivePaths: ['/test-center/dashboard', '/test-center/reservations'],
      isActiveKeyword: [],
      roles: DASHBOARD_ROLES,
    },
    {
      id: 2,
      path: '/test-center/class-sessions',
      title: t("exams_sessions"),
      className: 'fa',
      icon: faCalendar,
      isActivePaths: ['/test-center/class-sessions'],
      isActiveKeyword: ['class-sessions'],
      roles: SESSION_ROLES,
    },
    {
      id: 3,
      path: "/test-center/session-managment",
      title: t("session_management"),
      className: 'fa',
      icon: faListAlt,
      isActivePaths: ['/test-center/session-managment'],
      isActiveKeyword: [],
      roles: SESSION_MANAGEMENT_ROLES,

    },
    {
      id: 4,
      path: 'test-center/upcoming-exams',
      title: t("upcoming_tests"),
      className: 'fa',
      icon: faList,
      isActivePaths: ['/test-center/upcoming-exams'],
      isActiveKeyword: [],
      roles: UPCOMING_EXAMS_ROLES,
    },
    {
      id: 5,
      path: '/test-cycle',
      title: t("start_test"),
      className: 'fa',
      icon: faPlay,
      isActivePaths: [],
      isActiveKeyword: [],
      roles: TEST_CYCLE_ROLES,
    },
    // {
    //   id: 6,
    //   path: "/",
    //   title: t("payable_invoices"),
    //   className: 'icon invoice-icon fa-new-icon',
    //   icon: null,
    //   isActivePaths: [],
    //   isActiveKeyword: [],
    //   roles: PAYABLE_INVOICE_ROLES,

    // },
    {
      id: 7,
      path: "/test-center/profile",
      title: t("profile"),
      className: 'fa',
      icon: faGear,
      isActivePaths: ['/test-center/profile'],
      isActiveKeyword: ['profile', 'edit'],
      roles: PROFILE_ROLES,

    },
    {
      id: 8,
      path: "/test-center/attendance",
      title: t("attendance"),
      className: 'fa',
      icon: faCheckCircle,
      isActivePaths: ['/test-center/attendance'],
      isActiveKeyword: [],
      roles: ATTENDANCE_ROLES,
    },
  ];
  return items;
};

export const getMenuItemsByRole = (role) => {
  return menuItems().filter((item) => item.roles.includes(role));
};
