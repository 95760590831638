import axios from "axios";

// const DEFAULT_BASE_API = "https://localhost.qiwa.info:3000/api/v1";
// const DEFAULT_BASE_API = "https://svp-local-api.svp.stage.devops.takamol.support/api/v1";
const DEFAULT_BASE_API = "https://svp-local-api.svp.uat.devops.takamol.support/api/v1";

const remoteLoadEnvVariables = async () => {
  try {
    const { data } = await axios.get("/config.json");
    sessionStorage.setItem("REACT_APP_BASE_URL", data?.REACT_APP_BASE_URL);
    sessionStorage.setItem("REACT_APP_FRONTEND_HOST", data?.REACT_APP_FRONTEND_HOST);
    sessionStorage.setItem("REACT_APP_RECAPTCHA_SITE_KEY", data?.REACT_APP_RECAPTCHA_SITE_KEY);
    sessionStorage.setItem("REACT_APP_WEBSOCKET_SERVER_URL", data?.REACT_APP_WEBSOCKET_SERVER_URL);
    sessionStorage.setItem("REACT_APP_BACKEND_BASE_URL", data?.REACT_APP_BACKEND_BASE_URL);
    sessionStorage.setItem("REACT_APP_COMPANY_ADMIN_REGISTERATION", data?.REACT_APP_COMPANY_ADMIN_REGISTERATION);
    sessionStorage.setItem("REACT_APP_GOOGLE_API_KEY", data?.REACT_APP_GOOGLE_API_KEY);
    sessionStorage.setItem("REACT_APP_QIWA_CONTEXT", data?.REACT_APP_QIWA_CONTEXT);
    sessionStorage.setItem("COMPANY_LOGIN_BASE_URL", data?.COMPANY_LOGIN_BASE_URL);
    sessionStorage.setItem("QIWA_AUTH_CONTEXT", data?.QIWA_AUTH_CONTEXT);
    sessionStorage.setItem("ZENDESK_KEY", data?.ZENDESK_KEY);
    sessionStorage.setItem("QIWA_SSO_BASE_URL", data?.QIWA_SSO_BASE_URL);
    sessionStorage.setItem("QIWA_SSO_CLIENT_ID", data?.QIWA_SSO_CLIENT_ID);
    sessionStorage.setItem("QIWA_SSO_REDIRECT_URI", data?.QIWA_SSO_REDIRECT_URI);
    sessionStorage.setItem("QIWA_SSO_CODE_CHALLENGE", data?.QIWA_SSO_CODE_CHALLENGE);
    sessionStorage.setItem("SHOW_MOCK_COMPANY_SSO", data?.SHOW_MOCK_COMPANY_SSO);
    sessionStorage.setItem("REACT_APP_ENABLE_CLARITY", data?.REACT_APP_ENABLE_CLARITY);
    sessionStorage.setItem("REACT_APP_CLARITY_ID", data?.REACT_APP_CLARITY_ID);
    sessionStorage.setItem("REACT_APP_ENABLE_HOTJAR", data?.REACT_APP_ENABLE_HOTJAR);
    sessionStorage.setItem("REACT_APP_HOTJAR_ID", data?.REACT_APP_HOTJAR_ID);
  } catch (err) {
    console.error(err);
  }
};

const loadLocalEnvVariables = () => {
  sessionStorage.setItem("REACT_APP_BASE_URL", process.env.REACT_APP_BASE_URL);
  sessionStorage.setItem("REACT_APP_FRONTEND_HOST", process.env.REACT_APP_FRONTEND_HOST);
  sessionStorage.setItem("REACT_APP_RECAPTCHA_SITE_KEY", process.env.REACT_APP_RECAPTCHA_SITE_KEY);
  sessionStorage.setItem("REACT_APP_WEBSOCKET_SERVER_URL", process.env.REACT_APP_WEBSOCKET_SERVER_URL);
  sessionStorage.setItem("REACT_APP_BACKEND_BASE_URL", process.env.REACT_APP_BACKEND_BASE_URL);
  sessionStorage.setItem("REACT_APP_COMPANY_ADMIN_REGISTERATION", process.env.REACT_APP_COMPANY_ADMIN_REGISTERATION);
  sessionStorage.setItem("REACT_APP_GOOGLE_API_KEY", process.env.REACT_APP_GOOGLE_API_KEY);
  sessionStorage.setItem("REACT_APP_QIWA_CONTEXT", process.env.REACT_APP_QIWA_CONTEXT);
  sessionStorage.setItem("COMPANY_LOGIN_BASE_URL", process.env.CREACT_APP_OMPANY_LOGIN_BASE_URL);
  sessionStorage.setItem("QIWA_AUTH_CONTEXT", process.env.REACT_APP_QIWA_AUTH_CONTEXT);
  sessionStorage.setItem("ZENDESK_KEY", process.env.REACT_APP_ZENDESK_KEY);
  sessionStorage.setItem("QIWA_SSO_BASE_URL", process.env.REACT_APP_QIWA_SSO_BASE_URL);
  sessionStorage.setItem("QIWA_SSO_CLIENT_ID", process.env.REACT_APP_QIWA_SSO_CLIENT_ID);
  sessionStorage.setItem("QIWA_SSO_REDIRECT_URI", process.env.REACT_APP_QIWA_SSO_REDIRECT_URI);
  sessionStorage.setItem("QIWA_SSO_CODE_CHALLENGE", process.env.REACT_APP_QIWA_SSO_CODE_CHALLENGE);
  sessionStorage.setItem("SHOW_MOCK_COMPANY_SSO", process.env.REACT_APP_SHOW_MOCK_COMPANY_SSO);
  sessionStorage.setItem("REACT_APP_ENABLE_CLARITY", process.env.REACT_APP_ENABLE_CLARITY);
  sessionStorage.setItem("REACT_APP_CLARITY_ID", process.env.REACT_APP_CLARITY_ID);
  sessionStorage.setItem("REACT_APP_ENABLE_HOTJAR", process.env.REACT_APP_ENABLE_HOTJAR);
  sessionStorage.setItem("REACT_APP_HOTJAR_ID", process.env.REACT_APP_HOTJAR_ID);
};

export async function initConfig() {
  if (process.env.NODE_ENV === 'development') {
    loadLocalEnvVariables();
  } else {
    await remoteLoadEnvVariables();
  }
}

export function getBaseApi() {
  return sessionStorage.getItem("REACT_APP_BASE_URL") ?? DEFAULT_BASE_API;
}

export function getFrontEndHost() {
  return sessionStorage.getItem("REACT_APP_FRONTEND_HOST");
}

export function getRecaptchaSiteKey() {
  return sessionStorage.getItem("REACT_APP_RECAPTCHA_SITE_KEY");
}

export function getWebSocketURL() {
  return sessionStorage.getItem("REACT_APP_WEBSOCKET_SERVER_URL");
}

export function getBackEndBaseURL() {
  return sessionStorage.getItem("REACT_APP_BACKEND_BASE_URL");
}

export function getCompanySignUpURL() {
  return sessionStorage.getItem("REACT_APP_COMPANY_ADMIN_REGISTERATION");
}

export function getGoogleMapAPI() {
  return sessionStorage.getItem("REACT_APP_GOOGLE_API_KEY");
}

export function getSSOContext() {
  return sessionStorage.getItem("REACT_APP_QIWA_CONTEXT");
}

export function getZendeskKey() {
  return sessionStorage.getItem("ZENDESK_KEY");
}

export function getCompanyLoginUrl() {
  return sessionStorage.getItem("COMPANY_LOGIN_BASE_URL");
}

export function getQiwaAuthContextUrl() {
  return sessionStorage.getItem("QIWA_AUTH_CONTEXT");
}

export function getQiwaSsoBaseUrl() {
  return sessionStorage.getItem("QIWA_SSO_BASE_URL");
}

export function getQiwaSsoClientId() {
  return sessionStorage.getItem("QIWA_SSO_CLIENT_ID");
}

export function getQiwaSsoRedirectUri() {
  return sessionStorage.getItem("QIWA_SSO_REDIRECT_URI");
}

export function getQiwaSsoCodeChallenge() {
  return sessionStorage.getItem("QIWA_SSO_CODE_CHALLENGE");
}

export function getShowMockSSO() {
  return sessionStorage.getItem("SHOW_MOCK_COMPANY_SSO") ?? "no";
}

export function getEnableClarity() {
  return sessionStorage.getItem("REACT_APP_ENABLE_CLARITY") ?? false;
}

export function getEnableHotjar() {
  return sessionStorage.getItem("REACT_APP_ENABLE_HOTJAR") ?? false;
}

export function getClarityId() {
  return sessionStorage.getItem("REACT_APP_CLARITY_ID") ?? false;
}
export function getHotjarId() {
  return sessionStorage.getItem("REACT_APP_HOTJAR_ID") ?? false;
}
