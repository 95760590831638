import SideBarStyle from './SideBarStyle.module.scss';
import QiwaCompanySideBar from "./QiwaCompanySideBar";
import QiwaTestCenterSideBar from './QiwaTestCenterSideBar';

const QiwaSideBar = () => {
  const currentUser = "test center";
  return (
    <div className={`${SideBarStyle.navQiwa}`}>
      <ul>
        {
          currentUser === 'company' && <QiwaCompanySideBar />
        }
        {
          currentUser === 'test center' && <QiwaTestCenterSideBar />
        }
      </ul>
    </div>
  );
};

export default QiwaSideBar;
