import { useContext } from 'react';
import PropTypes from 'prop-types';
import AuthContext from 'src/context/AuthContext';
import Error404 from 'src/components/Errors/Error404';

const CompanyRoute = ({ children }) => {
  const { isCompanyAdmin } = useContext(AuthContext);

  if (isCompanyAdmin()) {
    return children;
  }

  return <Error404 />;
};

export default CompanyRoute;

CompanyRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
