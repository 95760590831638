import { useTranslation } from 'react-i18next';
import error404 from "src/assets/images/errors/error-404.png";
import { useNavigate } from 'react-router-dom';
import AppButton from 'src/components/shared/AppButton/AppButton';

const Error500 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('errors');
  const goHome = () => {
    navigate('/', { replace: true });
    window.location.reload();
  };
  return (
    <div className="text-center pt-4 error-pages">
      <img src={error404} alt="" className="error-img" />
      <h1 className="error-title">{t('errorPages.500.title')}</h1>
      <p className="error-name">{t('errorPages.500.name')}</p>
      <span className="error-discription">{t('errorPages.500.description')}</span>
      <span className="error-discription">{t('errorPages.500.details')}</span>
      <AppButton
        onClick={goHome}
        text={t('errorPages.backHome')}
        ModuleClassName="btn-primary"
        className="back-to-hpage"
      />
    </div>
  );
};

export default Error500;
