export const BAD_REQUEST_ERROR = 400;
export const AUTHNETICATION_ERROR = 401; // Not Authenticated
export const NOT_FOUND_ERROR = 404;
export const SERVER_ERROR = 500;
export const SUSPENDED_ERROR = 403;
export const errorsSet = new Set([
  BAD_REQUEST_ERROR,
  AUTHNETICATION_ERROR,
  NOT_FOUND_ERROR,
  SUSPENDED_ERROR,
  SERVER_ERROR,
]);
