import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import AppLinkStyle from 'src/components/shared/AppLink/AppLinkStyle.module.scss';
import AppButtonStyle from './AppButtonStyle.module.scss';

const AppButton = (props) => {
  const {
    text,
    size,
    onClick,
    disabledBtn,
    className,
    ModuleClassName,
    variant,
    type,
    title,
    icon,
    moduleClassName,
    ...rest
  } = props;
  return (
    <Button
      onClick={onClick}
      disabled={disabledBtn}
      size={size}
      className={` ${className} ${AppButtonStyle[ModuleClassName]} ${AppLinkStyle[moduleClassName]}`}
      variant={variant}
      type={type}
      title={title}
      {...rest}
    >
      {icon}
      {text}
    </Button>
  );
};

AppButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func,
  disabledBtn: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  ModuleClassName: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  moduleClassName: PropTypes.string,
};
AppButton.defaultProps = {
  size: 'md',
  onClick: () => {},
  disabledBtn: false,
  className: '',
  variant: 'primary',
  ModuleClassName: '',
  type: 'button',
  title: null,
  icon: '',
  moduleClassName: '',
};

export default AppButton;
