import axiosClient from "./apiClient";

export const getPhoneNumberInfo = () => {
  return axiosClient.get(`/phone_number`);
};

export const updatePhoneNumber = (payload) => {
  return axiosClient.put(`/phone_number`, payload);
};

export const verifyPhoneNumber = (payload) => {
  return axiosClient.post(`/phone_number/verify`, payload);
};

export const resendPhoneNumberOtp = (payload = {}) => {
  return axiosClient.post(`/phone_number/resend_otp`, payload);
};

export const verifyLater = () => {
  return axiosClient.post(`/phone_number/verify_later`);
};
