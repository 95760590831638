import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { updatePhoneNumber, verifyPhoneNumber } from "src/api/phoneVerificationApi";
import {
  LANDLINE_MOBILE_REGEX,
} from 'src/utils/regex';
import AppButton from '../shared/AppButton/AppButton';
import LinkStyle from '../shared/AppLink/AppLinkStyle.module.scss';
import UpdatePhone from './UpdatePhone';
import OtpVerification from './OtpVerification';

const PhoneVerification = ({
  phoneNumber, showModal, testTakerId, getTodayReservationsList, isPhoneVerified,
}) => {
  const { t } = useTranslation(['tc', 'common']);
  const [showUpdatePhoneModal, setShowUpdatePhoneModal] = useState(true);
  const [showVerificationModal, setShowVerificationModal] = useState(showModal);
  const handleVerificationModalClose = () => {
    if (!testTakerId) {
      sessionStorage.setItem("phoneVerificationModalShown", true);
    } else {
      setShowUpdatePhoneModal(true);
    }
    setShowVerificationModal(false);
  };
  const handleVerificationModalShow = () => setShowVerificationModal(true);
  const initialValues = {
    phone_number: phoneNumber,
    otp: "",
  };
  const [phoneInitialValues] = useState({ phone_number: "" });
  const [otpInitialValues] = useState({ otp: "" });
  const phoneValidationSchema = Yup.object().shape({
    phone_number: Yup.string().matches(
      LANDLINE_MOBILE_REGEX,
      t('errors:edit_profile.official_contact_number.invalid'),
    ).required(t('errors:required', { fieldName: t('profile:official_contact_number') })),
  });
  const otpValidationSchema = Yup.object().shape({
    otp: Yup.string().required(t('errors:required', { fieldName: t('tc:otp') })),
  });

  const updatePhoneNumberRequest = async (values, { setSubmitting, setErrors, actions }) => {
    let updatedValues = { ...values };
    if (testTakerId) {
      updatedValues = {
        ...values,
        test_taker_id: testTakerId,
      };
    }
    try {
      setSubmitting(true);
      await updatePhoneNumber(updatedValues);
      if (testTakerId) {
        getTodayReservationsList();
      }
      setShowUpdatePhoneModal(false);
      // Reset the form with new initialValues and validationSchema
      actions.resetForm({
        values: showUpdatePhoneModal ? phoneInitialValues : otpInitialValues,
      });
      actions.setValidationSchema(
        showUpdatePhoneModal ? phoneValidationSchema : otpValidationSchema,
      );
    } catch (error) {
      if (error.errors) {
        const { errors } = error;
        setErrors(errors);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const verifyPhoneNumberRequest = async (values, { setSubmitting, setErrors }) => {
    let updatedValues = { ...values };
    if (testTakerId) {
      updatedValues = {
        ...values,
        test_taker_id: testTakerId,
      };
    }
    try {
      setSubmitting(true);
      await verifyPhoneNumber(updatedValues);
      handleVerificationModalClose();
      if (!testTakerId) {
        localStorage.removeItem("phoneNumberInfo");
        sessionStorage.removeItem('phoneNumberUpdated');
      }
    } catch (error) {
      if (error.errors) {
        const { errors } = error;
        setErrors(errors);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = (values, { setSubmitting, setErrors, actions }) => {
    if (showUpdatePhoneModal) {
      updatePhoneNumberRequest(values, { setSubmitting, setErrors, actions });
    } else {
      verifyPhoneNumberRequest(values, { setSubmitting, setErrors, actions });
    }
  };
  return (
    <>
      {testTakerId && (
      <AppButton
        onClick={handleVerificationModalShow}
        variant='transparent'
        text={<FontAwesomeIcon icon={faPencil} />}
        className={`${LinkStyle['only-icon']} mx-1 p-0`}
      />
      )}

      <Modal show={showVerificationModal} onHide={handleVerificationModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('tc:confirm_mobile')}</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={showUpdatePhoneModal ? phoneValidationSchema : otpValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting, isValid, dirty,
          }) => {
            return (
              <Form>
                <Modal.Body>
                  <div className='w-100 text-center'>
                    <div className='w-75 mx-auto'>
                      {showUpdatePhoneModal
                        ? (
                          <UpdatePhone />
                        )
                        : (
                          <OtpVerification testTakerId={testTakerId} />
                        )}

                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between border-none mt-3">

                  {(!showUpdatePhoneModal && testTakerId) ? (
                    <AppButton
                      onClick={() => setShowUpdatePhoneModal(true)}
                      variant="transparent"
                      text={t('common:back')}
                      size="lg"
                      className={`${LinkStyle['btn-primary-outline']} ${LinkStyle['btn-md']}`}
                    />
                  )
                    : (
                      <AppButton
                        onClick={handleVerificationModalClose}
                        variant="transparent"
                        text={t('common:cancel')}
                        size="lg"
                        className={`${LinkStyle['btn-primary-outline']} ${LinkStyle['btn-md']}`}
                      />
                    )}

                  <AppButton
                    text={t('common:confirm')}
                    size="lg"
                    type="submit"
                    variant='transparent'
                    className={`${LinkStyle['btn-primary']} ${LinkStyle['btn-md']}`}
                    disabledBtn={!(isValid) || isSubmitting || (isPhoneVerified && !dirty)}
                  />
                </Modal.Footer>
              </Form>
            );
          } }
        </Formik>
      </Modal>
    </>
  );
};

PhoneVerification.propTypes = {
  phoneNumber: PropTypes.string,
  showModal: PropTypes.bool,
  testTakerId: PropTypes.number,
  getTodayReservationsList: PropTypes.func,
  isPhoneVerified: PropTypes.bool,
};

PhoneVerification.defaultProps = {
  phoneNumber: '',
  showModal: false,
  testTakerId: null,
  getTodayReservationsList: () => {},
  isPhoneVerified: false,

};
export default PhoneVerification;
