import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt, faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faGaugeHigh, faGear } from '@fortawesome/free-solid-svg-icons';
import AppLink from '../AppLink/AppLink';
import SideBarStyle from './SideBarStyle.module.scss';

const PathContainsKeyword = (keywords, path) => {
  const filteredKeywords = keywords.filter((keyword) => path.includes(keyword));
  if (filteredKeywords.length) return true;
  return false;
};

const SvpCompanySideBar = () => {
  const { t } = useTranslation('sidebar');
  const location = useLocation();
  const isActive = (path = [], keywords = []) => {
    return ((path && path.includes(location.pathname)) || (keywords && PathContainsKeyword(keywords, location.pathname))) ? SideBarStyle.active : '';
  };
  return (
    <>
      <li className={isActive(['/company/dashboard', '/company/book-appointment', '/company/workers-information'], ['reservations', 'test_takers'])}>
        <AppLink
          href="/company/dashboard"
          linkText={(
            <>
              <FontAwesomeIcon icon={faGaugeHigh} className="fa" />
              <span>
                {t('dashboard')}
              </span>
            </>
          )}
        />
      </li>
      <li className={isActive(['/company/packages'])}>
        <AppLink
          href="/company/packages"
          linkText={(
            <>
              <i className={`${SideBarStyle.sideIcon} ${SideBarStyle.package} fa`} />
              <span>
                {t('package')}
              </span>
            </>
          )}
        />
      </li>
      <li className={isActive(['/company/invoices'])}>
        <AppLink
          href="/company/invoices"
          linkText={(
            <>
              <FontAwesomeIcon icon={faListAlt} className="fa" />
              <span>
                {t('reservations_management')}
              </span>
            </>
          )}
        />
      </li>
      <li className={isActive(['/company/transactions'])}>
        <AppLink
          href="/company/transactions"
          linkText={(
            <>
              <FontAwesomeIcon icon={faCreditCard} className="fa" />
              <span>
                {t('transactions_list')}
              </span>
            </>
          )}
        />
      </li>
      <li className={isActive(['/company/profile', '/company/edit-profile'])}>
        <AppLink
          href="/company/profile"
          linkText={(
            <>
              <FontAwesomeIcon icon={faGear} className="fa" />
              <span>
                {t('setting')}
              </span>
            </>
          )}
        />
      </li>
    </>
  );
};

export default SvpCompanySideBar;
