import { createSearchParams } from "react-router-dom";
import axiosClient from "./apiClient";

export const listNotifications = (data) => {
  const params = createSearchParams(data);
  return axiosClient.get(`/notifications?${params}`);
};

export const markNotificationAsSeen = (id, data) => {
  return axiosClient.put(`/notifications/${id}`, data);
};
