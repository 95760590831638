export const COMPANY_ADMIN_USER_TYPE = 'company_admin';
export const CENTER_ADMIN_USER_TYPE = 'center_admin';
export const COMPANY_SIGN_IN_PATH = '/sign-in';
export const CLASS_SESSION_MINIMUM_SEATS = 10;
export const ITEMS_PER_PAGE = 10;
export const NOTIFICATION_ITEMS_PER_PAGE = 15;
export const MIN_CANCEL_REASON_LENGTH = 1;
// To be removed
export const LOGIN_PATHS = ['/test-center/login', '/workspaces', '/test-center/otp', '/callback', '/test-cycle', '/password/edit', '/password/new'];
export const NoHeaderPATHS = ['/test-center/otp', '/test-center/login', '/test-cycle', '/password/edit', '/password/new', '/callback'];
export const ERROR_PATHS = ['/500', '/404', '/403'];
export const NO_SIDEBAR_PATHS = ['/500', '/404', '/403', '/company/packages/purchase', '/test-cycle', '/terms-of-service/terms', '/change-password', '/', '/test-center-search'];
export const SEATCH_REF = "searchTC";
export const SUBACCOUNTS_TYPES = {
  assessor: 'Assessor', supervisor: 'Supervisor', receptionist: 'Receptionist', financial_admin: 'FinancialAdmin',
};
export const PAYMENT_FORM_OPTIONS = {
  style: "card",
  iframeStyles: {
    'card-number': {
      color: '#ff0000',
      'font-size': '16px',
      'font-family': 'monospace',
    },
    cvv: {
      color: '#0000ff',
      'font-size': '16px',
      'font-family': 'Arial',
    },
  },
  billingAddress: {
    country: "SA",
    state: "",
    city: "",
    postcode: "",
    street1: "",
    street2: "",
  },
  mandatoryBillingFields: {
    country: true,
    state: true,
    city: true,
    postcode: true,
    street1: true,
    street2: false,
  },
  locale: localStorage.getItem('lng'),
};
export const BOOKING_STEPS = {
  book_city_date: 1,
  book_center_branch: 2,
  book_exam_session: 3,
  book_exam_language: 4,
};
export const START_EXAM_STEPS = {
  reservation_details: 1,
  test_taker_info: 2,
  assessor_info: 3,
  exam_type: 4,
  terms_and_condition: 5,
  start_exam: 6,
};

export const FILE_EXT = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
export const IMAGE_EXT = ['image/jpg', 'image/jpeg', 'image/png'];
export const PDF_EXT = ['application/pdf'];
export const GEOLOCATION_DEFAULT = { lat: 24.7136, lng: 46.6753 };
export const MAXIMUM_UPLOAD_SIZE = 5000000;
export const TEST_CENTER_PROFILE_TABS = {
  generalInfo: "general-info",
  branches: "branches",
  subAccounts: "sub-accounts",
};

export const CAPACITY_INFORMATION_TEMPLATE_PATH = 'docx/Test Center capacity information.docx';
export const BRANCH_FORM_INITIAL_VALUES = {
  name_ar: '',
  name_en: '',
  official_contact_number: '',
  category_ids: [],
  building_number: '',
  street_name: '',
  neighborhood: '',
  location_link: '',
  postal_code: '',
  additional_number: '',
  city_id: '',
  geolocation: GEOLOCATION_DEFAULT,
  bank_id: '',
  account_holder_name: '',
  iban: '',
  iban_attachment: null,
  capacity_information: null,
  center_branch_admins_attributes: [{ first_name: '', last_name: '', email: '' }],
};

export const EDIT_PROFILE_INITIAL_VALUES = {
  name_ar: '',
  name_en: '',
  location_link: '',
  license_number: '',
  logo_url: "",
  logo: null,
  website: '',
  official_contact_number: '',
  email: '',
  geolocation: null,
  license_date: null,
  license_end_date: null,
  registered_name: '',
  has_bank_account: '',
  bank_account: null,
  social_insurance_comment: '',
  zakat_certificate_comment: '',
  has_capacity_information: false,
  capacity_information: null,
  has_commercial_license: false,
  commercial_license: '',
  has_organizational_structure: false,
  organizational_structure: null,
  has_social_insurance: false,
  social_insurance: null,
  has_zakat_certificate: false,
  zakat_certificate: null,
  category_ids: [],
  bank_id: '',
  account_holder_name: '',
  iban: '',
  iban_attachment: null,
  building_number: '',
  street_name: '',
  neighborhood: '',
  postal_code: '',
  additional_number: '',
  city_id: '',
  zakat_certificate_expiry_date: '',
};
