import PropTypes from 'prop-types';
import loader from 'src/assets/images/loader.gif';
import style from './LoaderStyle.module.scss';

const AppLoader = ({ className, otherClass }) => {
  return (
    // eslint-disable-next-line max-len
    // send class inner-load if you want to load it inside element and remove it if it will be for all page
    <div className={` ${style[className]} ${otherClass} ${style.loader} text-center d-flex align-items-center justify-content-center`}>
      <div>
        <div className={`${style['loader-img']} m-auto`}>
          <img src={loader} alt="loader" />
        </div>
      </div>
    </div>
  );
};

AppLoader.propTypes = {
  className: PropTypes.string,
  otherClass: PropTypes.string,
};

AppLoader.defaultProps = {
  className: '',
  otherClass: '',
};

export default AppLoader;
