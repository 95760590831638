import PropTypes from "prop-types";
import { Field } from "formik";
import FormikInputStyle from "./FormikInputStyle.module.scss";
import FormikErrorMessage from "../ErrorMsg/FormikErrorMsg";

const FormikInput = ({
  id,
  type,
  name,
  placeholder,
  inputIcon,
  disabled,
  className,
  maxLength,
  moduleClassName,
  svsIcon,
  inputGlobalClassName,
  containerClassName,
  showErrors,
  ...rest
}) => {
  return (
    <div className={`${FormikInputStyle[[className]]} ${containerClassName} `}>
      <Field name={name}>
        {({ field }) => {
          return (
            <>
              {svsIcon}
              <input
                {...field}
                id={id}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                maxLength={maxLength}
                className={`${FormikInputStyle[[moduleClassName]]} ${inputGlobalClassName}`}
                {...rest}
              />
              {inputIcon}
              {showErrors && <FormikErrorMessage fieldName={field.name} />}
            </>
          );
        }}
      </Field>
    </div>
  );
};

FormikInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  inputIcon: PropTypes.node,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  moduleClassName: PropTypes.string,
  svsIcon: PropTypes.node,
  inputGlobalClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  showErrors: PropTypes.bool,
};

FormikInput.defaultProps = {
  type: "text",
  className: "",
  placeholder: null,
  name: null,
  inputIcon: null,
  disabled: false,
  maxLength: null,
  moduleClassName: "",
  svsIcon: null,
  inputGlobalClassName: "",
  containerClassName: "",
  showErrors: true,
};

export default FormikInput;
