import SideBarStyle from './SideBarStyle.module.scss';
import QiwaSideBar from './QiwaSideBar';
import SvpSideBar from './SvpSideBar';

const AppSideBar = () => (
  <div className={`${SideBarStyle.sidebarNav}`}>
    <QiwaSideBar />
    <SvpSideBar />
  </div>
);

export default AppSideBar;
