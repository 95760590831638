import axiosClient from "./apiClient";

export const userLogin = (data) => {
  return axiosClient.post("/auth/login", data);
};

export const verifyOTP = (data) => {
  return axiosClient.post("/auth/verify", data);
};

export const checkUserLoggedIn = () => {
  return axiosClient.get("/auth/user-info");
};

export const userLogout = (data) => {
  return axiosClient.post("/auth/logout", data);
};

export const updateWoker = (id, data) => {
  return axiosClient.put(`/test_takers/${id}`, data);
};

export const myProfile = ({ is_edit }) => {
  if (is_edit) return axiosClient.get('/profile?is_edit=true');
  return axiosClient.get('/profile');
};

export const showUser = (id) => {
  return axiosClient.get(`/users/${id}`);
};
