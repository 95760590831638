import { useContext } from "react";
import authContext from "src/context/AuthContext";
import SideBarStyle from './SideBarStyle.module.scss';
import SvpCompanySideBar from './SvpCompanySidebar';
import SvpTestCenterSideBar from './SvpTestCenterSideBar';

const SvpSideBar = () => {
  const { isCompanyAdmin, isTestCenterAdmin } = useContext(authContext);
  return (
    <div className={`${SideBarStyle.navContent}`}>
      <ul>
        {
          isCompanyAdmin() && <SvpCompanySideBar />
        }
        {
          isTestCenterAdmin() && <SvpTestCenterSideBar />
        }
      </ul>
    </div>
  );
};

export default SvpSideBar;
