import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { resendPhoneNumberOtp } from "src/api/phoneVerificationApi";
import { useFormikContext } from 'formik';
import AppButton from 'src/components/shared/AppButton/AppButton';
import FormikInput from 'src/components/shared/Form/Input/FormikInput';
import PropTypes from 'prop-types';

const OtpVerification = ({ testTakerId }) => {
  const { t } = useTranslation(['tc', 'common']);
  const [time, setTime] = useState(null); // 6 seconds
  const {
    values, handleChange,
  } = useFormikContext();
  const resendOtpRequest = async () => {
    let updatedValues = {};
    if (testTakerId) {
      updatedValues = {
        test_taker_id: testTakerId,
      };
    }
    try {
      await resendPhoneNumberOtp(updatedValues);
      // Start the countdown after receiving the response
      setTime(6); // 6 seconds
    } catch (error) {
      const { errors } = error;
      console.log(errors);
    }
  };
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (time > 0) {
      const timerId = setTimeout(() => {
        setTime(time - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    }
    setTime(null);
  }, [time]);
  return (
    <>
      <p className='text-black font-size-16 font-light'>
        {t('tc:otp_code')}
      </p>
      <div>
        <FormikInput
          id="otp"
          name='otp'
          moduleClassName='general-input'
          containerClassName="d-inline-block w-80 mb-4"
          inputGlobalClassName="w-100 d-inline-block with-plus"
          value={values.fieldName} // Provide the value prop
          onChange={handleChange} // Handle value changes
        />
      </div>
      <AppButton
        text={t('tc:resend_code')}
        moduleClassName="light-link"
        className='font-size-16'
        onClick={resendOtpRequest}
        disabledBtn={!!time}
        variant='transparent'
      />
      {time && (
      <span className='small-font-size-14 mx-2 color-semi-black font-light'>
        (
        {time}
        )
      </span>
      )}

    </>
  );
};

OtpVerification.propTypes = {
  testTakerId: PropTypes.number,
};

OtpVerification.defaultProps = {
  testTakerId: null,

};

export default OtpVerification;
