import { ToastContainer } from 'react-toastify';
import { Suspense, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import "react-toastify/dist/ReactToastify.css";
import "src/assets/styles/App.scss";
import Layout from "src/components/Layouts/Layout";
import Loader from "src/components/shared/Loader/Loader";
import { ErrorPageHandler } from 'src/components/Errors/ErrorPageHandler';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import {
  getBackEndBaseURL,
  getRecaptchaSiteKey,
  initConfig,
  getEnableClarity,
  getClarityId,
  getEnableHotjar,
  getHotjarId,
} from "src/config/config";
import { clarity } from 'react-microsoft-clarity';
import Hotjar from '@hotjar/browser';
import AppRoutes from "./Routes/AppRoutes";
import { AuthProvider } from "./context/AuthContext";
import ZendeskWidget from './components/Zendesk/ZendeskWidget';

const App = () => {
  const [locale, setLocale] = useState(i18n.language);
  const [dir, setDir] = useState(i18n.language === 'ar' ? 'rtl' : 'ltr');
  i18n.on('languageChanged', () => { setLocale(i18n.language); setDir(i18n.language === 'ar' ? 'rtl' : 'ltr'); });
  const { t } = useTranslation('titles');
  const location = useLocation();
  const [reCaptchaKey, setReCaptchaKey] = useState(getRecaptchaSiteKey());

  useEffect(() => {
    // clear session storage when route changes
    if (location.pathname !== '/test-cycle') {
      sessionStorage.removeItem('testCycleActiveSteps');
      sessionStorage.removeItem('testCycleTestTakerInfo');
      sessionStorage.removeItem('testCycleExamInfo');
      sessionStorage.removeItem('testCycleExamUrl');
    }
  }, [location]);

  useEffect(() => {
    if (!getBackEndBaseURL()) {
      initConfig().then(() => {
        setReCaptchaKey(getRecaptchaSiteKey());
      });
    }
    if (getEnableClarity()) {
      clarity.init(getClarityId());
    }
    if (getEnableHotjar()) {
      Hotjar.init(getHotjarId(), 6);
    }
    console.log('Clarity started: ', clarity.hasStarted());
  }, []);

  if (!getBackEndBaseURL()) {
    return <Loader />;
  }

  return (
    <AuthProvider values={{ locale, setLocale }}>
      <Suspense fallback={<Loader />}>
        <Helmet htmlAttributes={{
          lang: locale,
          dir,
        }}
        >
          <title>{t('dashboard')}</title>
        </Helmet>
        <ErrorPageHandler>
          <div className="App" style={{ direction: dir }}>
            <ToastContainer position="bottom-right" />
            <Layout>
              <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
                <AppRoutes />
              </GoogleReCaptchaProvider>
              <ZendeskWidget />
            </Layout>
          </div>
        </ErrorPageHandler>
      </Suspense>
    </AuthProvider>

  );
};

export default App;
