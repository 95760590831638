import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  TESTCENTER_ADMIN, DASHBOARD_ROLES, SESSION_ROLES, SESSION_MANAGEMENT_ROLES,
  UPCOMING_EXAMS_ROLES, TEST_CYCLE_ROLES,
  PROFILE_ROLES, MANAGE_BRANCHES_ROLES, SESSION_VIEW_ROLES, ATTENDANCE_ROLES,
} from 'src/utils/roles';
import NotFoundPage from 'src/components/Errors/Error404';
import Loader from "src/components/shared/Loader/Loader";
// import TCSignUp from 'src/components/TCSignUp';
import UnKnownRoute from './UnKnownRoute';
import CompanyRoute from './CompanyRoute';
import TestCenterRoute from './TestCenterRoute';
import ProtectedRoute from './ProtectedRoute';

// TC Routes
const TCLogin = lazy(() => import('src/pages/test-center/login'));
const OTP = lazy(() => import('src/pages/test-center/otp'));
const TcDashboardPage = lazy(() => import('src/pages/test-center/dashboard'));
const Home = lazy(() => import('src/pages/home'));
const ClassSessions = lazy(() => import('src/pages/test-center/class-sessions/index'));
const ClassSessionDetails = lazy(() => import('src/components/TCClassSession/ClassSessionDetails'));
const ReservationDetails = lazy(() => import('src/components/TCClassSession/ReservationDetails'));
const StartExamPage = lazy(() => import('src/pages/test-center/startExam'));
const ShowProfilePage = lazy(() => import('src/pages/test-center/showProfile'));
const AddBranch = lazy(() => import('src/components/TCProfile/Branches/AddBranch'));
const EditBranch = lazy(() => import('src/components/TCProfile/Branches/EditBranch'));
const EditProfilePage = lazy(() => import('src/pages/test-center/editProfile'));
const AttendancePage = lazy(() => import('src/pages/test-center/attendance'));

const PackagesPage = lazy(() => import('src/pages/company/packages'));
const BookExamClassSession = lazy(() => import('src/components/CompanyBookExam/BookExamClassSession/index'));
const RerservationSummary = lazy(() => import('src/components/CompanyBookExam/BookingSummary/index'));
const BookingDetails = lazy(() => import('src/components/CompanyBookExam/BookingDetails/index'));
const SessionManagement = lazy(() => import('src/components/SessionManagment/index'));
const UpcomigExam = lazy(() => import('src/pages/test-center/reservations/upcoming'));
const AllReservations = lazy(() => import('src/pages/test-center/reservations/index'));

const TCSignUp = lazy(() => import('src/components/TCSignUp/index'));
// Company Routes
const Workspaces = lazy(() => import('src/pages/workspaces/index'));
const Callback = lazy(() => import('src/pages/callback'));
const DashboardPage = lazy(() => import('src/pages/company/dashboard'));
const WorkersInformationPage = lazy(() => import('src/pages/company/workersInformation'));
const BookingWorkersList = lazy(() => import('src/components/CompanyBookExam'));
const PaymentForm = lazy(() => import('src/components/Packages/PaymentForm'));
const Transactions = lazy(() => import('src/components/TransactionList'));
const CompanyReservationsPage = lazy(() => import('src/pages/company/reservations'));
const WorkerProfile = lazy(() => import('src/components/WorkerProfile'));
const CompanyProfilePage = lazy(() => import('../pages/company/profile'));
const CompanyEditProfilePage = lazy(() => import('../pages/company/editProfile'));

// Public routes
const ForgetPassword = lazy(() => import('src/components/Passwords/ForgetPassword'));
const ChangePassword = lazy(() => import('src/components/Passwords/ChangePassword'));
const ResetPassword = lazy(() => import('src/components/Passwords/ResetPassword'));
const PrometricFailure = lazy(() => import('src/components/StartExam/ExamFailed'));
const ThankYouPage = lazy(() => import('src/components/StartExam/ExamDone'));
const TermsAndCondition = lazy(() => import('src/components/TermsAndConditions'));
const DiscoverPage = lazy(() => import('src/components/Discover/DiscoverPage'));
const KnowledgeCenterPage = lazy(() => import('src/pages/knowledge-center/KnowledgeCenterPage'));
const FaqPage = lazy(() => import('src/pages/knowledge-center/FaqPage'));
const CategoriesPage = lazy(() => import('src/pages/knowledge-center/CategoriesPage'));
const TestCenterSearch = lazy(() => import('src/components/TestCenterSearch/TestCenterSearch'));
const WorkerEligibilty = lazy(() => import('src/components/WorkerEligibility/WorkerEligibility'));

const AppRoutes = () => (
  <Suspense fallback={<Loader />}>
    <Routes>
      {/* public pages */}
      <Route
        exact
        path="/prometric-failure"
        element={(
          <PrometricFailure />
        )}
      />
      <Route
        exact
        path="/test-center-search"
        element={(
          <TestCenterSearch />
        )}
      />
      <Route
        exact
        path="/thank-you"
        element={(
          <ThankYouPage />
        )}
      />
      <Route
        exact
        path="/terms-of-service/:id"
        element={(
          <TermsAndCondition />
        )}
      />
      <Route
        exact
        path="/journey"
        element={(
          <DiscoverPage />
        )}
      />
      <Route
        exact
        path="/knowledge-center"
        element={(
          <KnowledgeCenterPage />
        )}
      />
      <Route
        exact
        path="/faq"
        element={(
          <FaqPage />
        )}
      />
      <Route
        exact
        path="/categories-and-occupations"
        element={(
          <CategoriesPage />
        )}
      />
      <Route
        exact
        path="/password/edit"
        element={(
          <ResetPassword />
        )}
      />
      <Route
        exact
        path="/worker-eligibilty"
        element={(
          <WorkerEligibilty />
        )}
      />

      <Route exact path='/password/new' element={<ForgetPassword />} />
      <Route
        exact
        path="change-password"
        element={(
          <ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>
          )}
      />
      {/** Test center routes */}
      <Route path="test-center">
        <Route
          exact
          path="login"
          element={(
            <TCLogin />
          )}
        />

        <Route
          exact
          path="signup"
          element={(
            <TCSignUp />
          )}
        />

        <Route
          exact
          path="otp"
          element={(
            <ProtectedRoute>
              <OTP />
            </ProtectedRoute>
          )}
        />

        <Route
          exact
          path="dashboard"
          element={(
            <ProtectedRoute>
              <TestCenterRoute allowedRoles={DASHBOARD_ROLES}>
                <TcDashboardPage />
              </TestCenterRoute>
            </ProtectedRoute>
          )}

        />

        <Route
          exact
          path="attendance"
          element={(
            <ProtectedRoute>
              <TestCenterRoute allowedRoles={ATTENDANCE_ROLES}>
                <AttendancePage />
              </TestCenterRoute>
            </ProtectedRoute>
        )}
        />

        <Route
          exact
          path="profile"
          element={(
            <ProtectedRoute>
              <TestCenterRoute allowedRoles={PROFILE_ROLES}>
                <ShowProfilePage />
              </TestCenterRoute>
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path="edit"
          element={(
            <ProtectedRoute>
              <TestCenterRoute allowedRoles={[TESTCENTER_ADMIN]}>
                <EditProfilePage />
              </TestCenterRoute>
            </ProtectedRoute>
          )}
        />
        <Route path="center_branch">
          <Route
            exact
            path="new"
            element={(
              <ProtectedRoute>
                <TestCenterRoute allowedRoles={MANAGE_BRANCHES_ROLES}>
                  <AddBranch />
                </TestCenterRoute>
              </ProtectedRoute>
          )}
          />
          <Route
            exact
            path=":id/edit"
            element={(
              <ProtectedRoute>
                <TestCenterRoute allowedRoles={MANAGE_BRANCHES_ROLES}>
                  <EditBranch />
                </TestCenterRoute>
              </ProtectedRoute>
          )}
          />
        </Route>

        <Route
          exact
          path="session-managment"
          element={(
            <ProtectedRoute>
              <TestCenterRoute allowedRoles={SESSION_MANAGEMENT_ROLES}>
                <SessionManagement />
              </TestCenterRoute>
            </ProtectedRoute>

          )}
        />
        <Route
          exact
          path="upcoming-exams"
          element={(
            <ProtectedRoute>
              <TestCenterRoute allowedRoles={UPCOMING_EXAMS_ROLES}>
                <UpcomigExam />
              </TestCenterRoute>
            </ProtectedRoute>

          )}
        />
        <Route
          exact
          path="reservations"
          element={(
            <ProtectedRoute>
              <TestCenterRoute allowedRoles={UPCOMING_EXAMS_ROLES}>
                <AllReservations />
              </TestCenterRoute>
            </ProtectedRoute>
          )}
        />
      </Route>

      <Route
        exact
        path="/callback"
        element={(
          <Callback />
        )}
      />
      <Route
        exact
        path="/test-cycle"
        element={(
          <ProtectedRoute>
            <TestCenterRoute allowedRoles={TEST_CYCLE_ROLES}>
              <StartExamPage />
            </TestCenterRoute>
          </ProtectedRoute>
          )}
      />
      <Route
        exact
        path="/workspaces"
        element={(
          <ProtectedRoute>
            <Workspaces />
          </ProtectedRoute>

        )}
      />

      {/* Company Routes */}
      <Route path="company">

        <Route
          path="transactions"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <Transactions />
              </CompanyRoute>
            </ProtectedRoute>
          )}
        />

        <Route
          path="dashboard"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <DashboardPage />
              </CompanyRoute>
            </ProtectedRoute>
        )}
        />

        <Route
          path="workers-information"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <WorkersInformationPage />
              </CompanyRoute>
            </ProtectedRoute>
          )}
        />

        <Route
          path="packages"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <PackagesPage />
              </CompanyRoute>
            </ProtectedRoute>
        )}
        />

        <Route
          path="profile"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <CompanyProfilePage />
              </CompanyRoute>
            </ProtectedRoute>
        )}
        />
        <Route
          path="edit-profile"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <CompanyEditProfilePage />
              </CompanyRoute>
            </ProtectedRoute>
        )}
        />

        <Route
          path="packages/purchase"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <PaymentForm />
              </CompanyRoute>
            </ProtectedRoute>
        )}
        />

        <Route
          path="book-appointment"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <BookingWorkersList />
              </CompanyRoute>
            </ProtectedRoute>
        )}
        />

        <Route path="reservations">
          <Route
            exact
            path="initialize"
            element={(
              <ProtectedRoute>
                <CompanyRoute>
                  <BookExamClassSession />
                </CompanyRoute>
              </ProtectedRoute>

        )}
          />
          <Route
            exact
            path="summary"
            element={(

              <ProtectedRoute>
                <CompanyRoute>
                  <RerservationSummary />
                </CompanyRoute>
              </ProtectedRoute>
        )}
          />

        </Route>
        <Route
          exact
          path="test_takers/:id"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <WorkerProfile />
              </CompanyRoute>
            </ProtectedRoute>
            )}
        />
        <Route
          path="invoices"
          element={(
            <ProtectedRoute>
              <CompanyRoute>
                <CompanyReservationsPage />
              </CompanyRoute>
            </ProtectedRoute>
          )}
        />

      </Route>
      <Route
        exact
        path="company/reservations/invoices/:id"
        element={(

          <ProtectedRoute>
            <CompanyRoute>
              <BookingDetails />
            </CompanyRoute>
          </ProtectedRoute>
        )}
      />

      <Route
        exact
        path="/"
        element={(
          <Home />
        )}
      />

      <Route
        exact
        path="/test-center/class-sessions"
        element={(
          <ProtectedRoute>
            <TestCenterRoute allowedRoles={SESSION_ROLES}>
              <ClassSessions />
            </TestCenterRoute>
          </ProtectedRoute>
        )}
      />
      <Route
        exact
        path="/test-center/class-sessions/:id"
        element={(
          <ProtectedRoute>
            <TestCenterRoute allowedRoles={SESSION_VIEW_ROLES}>
              <ClassSessionDetails />
            </TestCenterRoute>
          </ProtectedRoute>
        )}
      />
      <Route
        exact
        path="/test-center/reservations/:id"
        element={(
          <ProtectedRoute>
            <TestCenterRoute allowedRoles={SESSION_ROLES}>
              <ReservationDetails />
            </TestCenterRoute>
          </ProtectedRoute>
        )}
      />

      <Route path="/404" element={<NotFoundPage />} />

      <Route path="*" element={<UnKnownRoute />} />
    </Routes>
  </Suspense>
);

export default AppRoutes;
