import React, {
  useState, useEffect, useContext, createContext, useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import authContext from 'src/context/AuthContext';
import * as Errors from './ErrorCodes';
import Error404 from './Error404';
import Error500 from './Error500';

const errorPages = {
  [Errors.NOT_FOUND_ERROR]: Error404,
  [Errors.AUTHNETICATION_ERROR]: Error404,
  [Errors.BAD_REQUEST_ERROR]: Error500,
  [Errors.SUSPENDED_ERROR]: Error404,
  [Errors.SERVER_ERROR]: Error500,
};

const SetErrorContext = createContext();

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => { ref.current = value; });

  return ref.current;
};

export const ErrorPageHandler = ({ children }) => {
  const [error, setError] = useState();
  const ErrorPageComponent = errorPages[error];
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const navigate = useNavigate();
  const { logout } = useContext(authContext);
  const handleSuspendedError = () => {
    if (error === Errors.SUSPENDED_ERROR || error === Errors.AUTHNETICATION_ERROR) {
      logout();
      navigate('/');
    }
  };
  useEffect(() => {
    if (error && location !== prevLocation) setError(undefined); // clear error
    handleSuspendedError();
  }, [error, setError, location]);

  if (!error) {
    return (
      <SetErrorContext.Provider value={setError}>
        {children}
      </SetErrorContext.Provider>
    );
  }
  return <ErrorPageComponent />;
};

ErrorPageHandler.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SetErrorContext;
