import { useContext } from "react";
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import authContext from "src/context/AuthContext";
import { NO_SIDEBAR_PATHS } from "src/utils/constants";
import CompanyLayout from "./CompanyLayout";
import GuestLayout from "./GuestLayout";
import TcLayout from "./TcLayout";

const Layout = ({ children }) => {
  const location = useLocation();
  const { isCompanyAdmin, isTestCenterAdmin } = useContext(authContext);
  const hideSideBar = NO_SIDEBAR_PATHS.includes(location.pathname);

  if (isTestCenterAdmin()) { return <TcLayout showSideBar={!hideSideBar}>{children}</TcLayout>; }
  if (isCompanyAdmin()) return <CompanyLayout showSideBar={!hideSideBar}>{children}</CompanyLayout>;
  return <GuestLayout>{children}</GuestLayout>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
