import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import authContext from 'src/context/AuthContext';
import SVPLogo from 'src/assets/images/logos/svpLogo.png';
import enLang from 'src/assets/images/logos/uk.png';
import arLang from 'src/assets/images/logos/saudi.png';
import AppLink from 'src/components/shared/AppLink/AppLink';
import LinkStyle from 'src/components/shared/AppLink/AppLinkStyle.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import AppNavbarStyle from './AppNavbarStyle.module.scss';

const GuestNavbar = ({ className }) => {
  const { t, i18n } = useTranslation('common');
  const { locale, setLocale, generateCompanySignInUrl } = useContext(authContext);
  const changeLocale = (l) => {
    if (locale !== l) {
      i18n.changeLanguage(l);
      setLocale(l);
      window.location.reload(true);
    }
  };
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="white" fixed="top" className={`${AppNavbarStyle.appMainNavbar} ${AppNavbarStyle[className]} ${navbar && AppNavbarStyle.white}  px-3 `}>
      <Navbar.Brand href='/'>
        <img src={SVPLogo} alt="" width={180} className={`${AppNavbarStyle['logo-img']}`} />
      </Navbar.Brand>
      <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} className={`${expanded ? 'show-btn' : ''}`} aria-controls="responsive-navbar-nav">
        <FontAwesomeIcon icon={faBars} className={`${AppNavbarStyle['open-menu']} ${expanded ? 'd-none' : ''} open-menu`} />
        <FontAwesomeIcon icon={faTimes} className={`${expanded ? '' : 'd-none'} close-menu`} />
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav" className={`${expanded ? 'show' : 'd-none'} justify-content-end menu-margin`}>
        <AppLink
          linkText={t('common:discover')}
          href="/journey"
          moduleClassName="light-link"
          className={`${AppNavbarStyle['menu-link']} black-color mx-2 discover-page`}
          onClick={() => setExpanded(false)}
        />
        <AppLink
          linkText={t('common:knowledge_center')}
          href="/knowledge-center"
          moduleClassName="light-link"
          className={`${AppNavbarStyle['menu-link']} black-color mx-2 discover-page`}
          onClick={() => setExpanded(false)}
        />
        <Dropdown>
          <Dropdown.Toggle variant="transparnt" className={`${AppNavbarStyle['check-menu']} black-color mx-2 font-light`}>
            {t('common:check')}
          </Dropdown.Toggle>

          <Dropdown.Menu className='register_check_dd'>
            <Dropdown.Item href="/worker-eligibilty" className={`${AppNavbarStyle['check-menu-item']} text-start py-2`}>
              {t('common:eligibility_of_worker')}
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1" className={`${AppNavbarStyle['check-menu-item']} py-2 d-none`}>
              {t('common:eligibility_of_company')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className='mx-2'>
          <Dropdown.Toggle variant="transparent" className={`${LinkStyle['btn-primary']} ${LinkStyle['btn-md']} font-light px-3 border-none`}>
            {t('common:register_now')}
          </Dropdown.Toggle>
          <Dropdown.Menu className='register_check_dd'>
            <Dropdown.Item href="test-center/signup" className={`${AppNavbarStyle['check-menu-item']} py-2`}>
              {t('common:register_as_test_center')}
            </Dropdown.Item>
            <Dropdown.Item target="blank" href={generateCompanySignInUrl()} className={`${AppNavbarStyle['check-menu-item']} py-2`}>
              {t('common:register_as_company')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <NavDropdown
          title={locale === 'ar'
            ? (
              <span>
                عربى
                <img src={arLang} alt="" className='mx-2 header-lang' />
              </span>
            )
            : (
              <span>
                English
                <img src={enLang} alt="" className='mx-2 header-lang' />
              </span>
            )}
          className={`${AppNavbarStyle['lang-dropdown']} lang-dropdown`}
        >
          <NavDropdown.Item href="" onClick={() => { changeLocale('en'); }} className={`${AppNavbarStyle['lang-link']} ${locale === ('en') ? `${AppNavbarStyle['active-lang']}` : ''}`}>
            English
            <img src={enLang} alt="" className='mx-2 header-lang' />
          </NavDropdown.Item>
          <NavDropdown.Item href="" onClick={() => { changeLocale('ar'); }} className={`${AppNavbarStyle['lang-link']} ${locale === ('ar') ? `${AppNavbarStyle['active-lang']}` : ''}`}>
            العربية
            <img src={arLang} alt="" className='mx-2 header-lang' />
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};

GuestNavbar.propTypes = {
  className: PropTypes.string,
};

GuestNavbar.defaultProps = {
  className: "",
};

export default GuestNavbar;
