import { Container, Row } from 'react-bootstrap';
import takamolLogo from 'src/assets/images/logos/takamol-logo.png';
import mlsdLogoAr from 'src/assets/images/logos/mlsd_logo_ar.svg';
import AppFooterStyle from './AppFooterStyle.module.scss';
import AppLink from '../AppLink/AppLink';
import '../AppLink/AppLinkStyle.module.scss';

const LoginAppFooter = () => (
  <div className={`${AppFooterStyle.loginAppfooter}`}>
    <Container fluid>
      <Row className="justify-content-center">
        <div className='d-inline-block w-auto'>
          <ul className={`${AppFooterStyle.footerLogos}`}>
            <li>
              <AppLink linkText={<img src={mlsdLogoAr} alt="" />} href="https://mlsd.gov.sa/ar" />
              {/* <AppLink linkText={<Image src={mlsdLogoEn} alt="" />} href="/" /> */}
            </li>
            <li>
              <AppLink linkText={<img src={takamolLogo} alt="" />} href="https://takamolholding.com/ar" />
            </li>
          </ul>
        </div>
      </Row>
    </Container>
  </div>
);
export default LoginAppFooter;
