import { useEffect, useContext } from 'react';
import SetErrorContext from 'src/components/Errors/ErrorPageHandler';
import { NOT_FOUND_ERROR } from 'src/components/Errors/ErrorCodes';

export default () => {
  const setError = useContext(SetErrorContext);
  useEffect(() => {
    setError(NOT_FOUND_ERROR);
  }, []);

  return null;
};
