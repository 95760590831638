import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';
import { listNotifications, markNotificationAsSeen } from 'src/api/notificationApi';
import toastify from 'src/utils/toastify';
import { NOTIFICATION_ITEMS_PER_PAGE } from "src/utils/constants";
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Modal,
} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import * as ActionCable from 'actioncable';
import { getWebSocketURL } from 'src/config/config';
import AppNavbarStyle from '../AppNavbar/AppNavbarStyle.module.scss';

const AppNotification = () => {
  const { t } = useTranslation(['notifications']);
  const [isLoading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [currentNotification, setCurrentNotification] = useState(true);
  const [show, setShow] = useState(false);
  const fetchNotifications = async (pageNum = pageNumber) => {
    try {
      setLoading(true);
      const { data, metadata, unseen_notifications_count } = await listNotifications(
        { page: pageNum, per_page: NOTIFICATION_ITEMS_PER_PAGE },
      );
      setNotifications((prevNotifications) => [...prevNotifications, ...data]);
      setNotificationsCount(unseen_notifications_count);
      const totalPageCount = Math.ceil(metadata.total_count / metadata.per_page);
      if (totalPageCount === pageNumber) {
        setHasMore(false);
      }
      setPageNumber((prevPage) => prevPage + 1);
    } catch (err) {
      toastify('error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const { unseen_notifications_count } = await markNotificationAsSeen(
        notificationId,
        { seen: true },
      );
      setNotificationsCount(unseen_notifications_count);
      setNotifications([]);
      setPageNumber(1);
      fetchNotifications(1);
    } catch (err) {
      toastify('error', err.message);
    }
  };
  const handleShowNotification = (notification) => {
    setShow(true);
    setCurrentNotification(notification);
    if (!notification.seen) {
      markNotificationAsRead(notification.id);
    }
  };
  useEffect(() => {
    fetchNotifications();
  }, []);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const cable = ActionCable.createConsumer(`${getWebSocketURL()}`);
    console.log(cable);
    const notificationChannel = cable.subscriptions.create(`NotificationsChannel`, {
      received: (data) => {
        console.log(data);
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
        setNotificationsCount((prevNotificationsCount) => prevNotificationsCount + 1);
      },
    });

    return () => {
      cable.subscriptions.remove(notificationChannel);
    };
  }, []);
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('notifications:new_notification')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='notification-content text-center'>
            {currentNotification.text}
          </p>
        </Modal.Body>
      </Modal>
      <NavDropdown
        className={`${AppNavbarStyle.notificationDD} ${notifications.length === 0 && "no-notification"} px-md-0 notificationDD`}
        title={(
          <>
            <FontAwesomeIcon icon={faBell} className={`${AppNavbarStyle['menu-icon']}`} />
            {!!notificationsCount && <span className={`${AppNavbarStyle.notificationCount}`}>{notificationsCount}</span>}
          </>
    )}
        id="notifications-dropdown"
      >
        <NavDropdown.Header className={`${AppNavbarStyle.notificationHead} notificationHead`}>
          {t('notifications:notifications')}
        </NavDropdown.Header>
        <div id="dropdown-menu-scroll-container" className='dropdown-menu'>
          <InfiniteScroll
            dataLength={notifications.length}
            next={fetchNotifications}
            hasMore={hasMore}
            scrollableTarget="dropdown-menu-scroll-container"
          >

            {
        (notifications.length > 0 || isLoading)
          ? notifications.map((notification) => (
            <NavDropdown.Item
              key={notification.id}
              href={notification.url ? notification.url : ""}
              className={notification.seen ? "" : "unreaded-notification"}
              onClick={() => handleShowNotification(notification)}
            >
              <span className='bell-icon'>
                <FontAwesomeIcon icon={faBell} />
              </span>
              <div className='text-container'>
                <span>
                  {notification.text}
                </span>
                <span>
                  {notification.created_at}
                </span>
              </div>
            </NavDropdown.Item>
          )) : (
            <NavDropdown.Item className='no-notification-item'>
              {t('notifications:no_notifications')}
            </NavDropdown.Item>
          )
    }
          </InfiniteScroll>
        </div>

      </NavDropdown>
    </>

  );
};

export default AppNotification;
