import { useState, useEffect, useContext } from 'react';
import i18n from 'i18next';
import authContext from "src/context/AuthContext";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { getZendeskKey } from 'src/config/config';
import "./zendesk.scss";

const ZendeskWidget = () => {
  const [isZatcaLoaded, setIsZatcaLoaded] = useState(false);
  const { currentUser } = useContext(authContext);
  const locale = i18n.language;
  const setting = {
    color: {
      theme: "#17494D",
    },
    // Commented for future reference and customization
    // contactForm: {
    //   fields: [
    //     { id: "description", prefill: { "*": "My pre-filled description" } },
    //   ],
    // },
  };
  useEffect(() => {
    ZendeskAPI("webWidget", "setLocale", locale);
    ZendeskAPI('webWidget', 'identify', { name: currentUser?.full_name, email: currentUser?.email });
  }, [isZatcaLoaded, locale]);
  return (
    <Zendesk
      defer={false}
      zendeskKey={getZendeskKey()}
      {...setting}
      onLoaded={() => setIsZatcaLoaded(true)}
    />
  );
};

export default ZendeskWidget;
