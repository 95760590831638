import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import AppLink from '../AppLink/AppLink';
import SideBarStyle from './SideBarStyle.module.scss';

const QiwaTestCenterSideBar = () => {
  const { t } = useTranslation('sidebar');
  return (
    <li>
      <AppLink
        href="/"
        linkText={(
          <>
            <div className={`${SideBarStyle.navQiwaLogo}`}>
              <FontAwesomeIcon icon={faListCheck} className="fa" />
            </div>
            <span className={`${SideBarStyle.itemDescription} mx-auto align-self-center `}>{t('professional_accreditation_program')}</span>
          </>
        )}
      />
    </li>
  );
};

export default QiwaTestCenterSideBar;
