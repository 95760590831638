import { useContext } from 'react';
import PropTypes from 'prop-types';
import AuthContext from 'src/context/AuthContext';
import Error404 from 'src/components/Errors/Error404';

const ProtectedRoute = ({ children }) => {
  const {
    currentUser,
  } = useContext(AuthContext);

  if (!currentUser) {
    return <Error404 />;
  }
  return children;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
