/* eslint-disable no-underscore-dangle */
import axios from "axios";
import { setupCache, buildWebStorage } from 'axios-cache-interceptor';
import i18n from 'i18next';
// eslint-disable-next-line import/no-cycle
import Cookies from 'js-cookie';
import { clearUserData, homePagePathCurrentUser } from "src/utils/auth";
import { getBaseApi, getFrontEndHost } from "src/config/config";

const REFRESH_TOKEN_CODE = "0003";
const INACTIVE_USER_SESSION_CODE = "0016";

setupCache(axios, {
  // As localStorage is a public storage, you can add a prefix
  // to all keys to avoid collisions with other code.
  storage: buildWebStorage(localStorage, 'axios-cache:'),
});

const axiosClient = axios.create({
  baseURL: getBaseApi(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": getFrontEndHost(),
  },
  withCredentials: true,
});

let isLogoutInProgress = false;

export const userLogout = async (data) => {
  if (isLogoutInProgress) {
    return; // Exit if logout is already in progress
  }
  isLogoutInProgress = true;
  try {
    await axiosClient.post("/auth/logout", data);
  } catch (error) {
    clearUserData(error?.message);
  } finally {
    isLogoutInProgress = false;
    clearUserData();
  }
};

export const refreshToken = () => {
  return axiosClient.post("/auth/refresh_token");
};

const refreshTokenRequest = async () => {
  try {
    await refreshToken();
  } catch (err) {
    if (err.code === INACTIVE_USER_SESSION_CODE) {
      clearUserData();
    }
  }
};

axiosClient.interceptors.request.use(
  async (config) => ({ ...config, baseURL: getBaseApi() }),
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (error) => {
    const res = error?.response;
    const originalRequest = error.config;
    const user = JSON.parse(localStorage.getItem("user"));

    if (!res) return Promise.reject(new Error(i18n.t("errors:bad_request")));
    switch (res.status) {
      case 401:
        if (res.data.code === REFRESH_TOKEN_CODE && !originalRequest._retry) {
          originalRequest._retry = true;
          await refreshTokenRequest();
          return axiosClient(originalRequest);
        }
        await userLogout({ qiwa_token: Cookies.get('qiwa.authorization') });
        break;
      case 404:
        if (!window.location.href.includes('login')) window.location.href = '/404';
        break;
      case 403:
        if (user) {
          localStorage.setItem('ToastMessage', i18n.t('errors:non_priviledged_action'));
          window.location.href = homePagePathCurrentUser(user.role);
        }
        break;
      default:
        console.log("Error");
    }
    return Promise.reject(res.data);
  },
);

axiosClient.interceptors.request.use(
  (config) => {
    const configuration = config;
    configuration.headers["qiwa-jwt"] = config?.data?.qiwa_token || Cookies.get('qiwa.authorization');
    configuration.headers["Accept-Language"] = localStorage?.getItem('lng') || 'ar';
    return configuration;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosClient;
