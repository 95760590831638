import Cookies from 'js-cookie';
import {
  ASSESSOR, FINANCIAL_ADMIN, RECEPTIONIST, COMPANY_ADMIN,
} from "src/utils/roles";

export const clearUserData = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("phoneNumberInfo");
  localStorage.removeItem('selectedWorkers');
  localStorage.removeItem('selectedCategoryId');
  sessionStorage.removeItem('testCycleActiveSteps');
  sessionStorage.removeItem('testCycleTestTakerInfo');
  sessionStorage.removeItem('testCycleExamInfo');
  sessionStorage.removeItem('testCycleExamUrl');
  sessionStorage.removeItem('phoneVerificationModalShown');
  sessionStorage.removeItem("phoneNumberUpdated");

  Cookies.remove("loggedIn");
  Cookies.remove("qiwa.authorization");
  window.location.href = "/";
};

export const homePagePathCurrentUser = (role) => {
  let homePagePath;
  switch (role) {
    case ASSESSOR:
      homePagePath = "/test-cycle";
      break;
    case RECEPTIONIST:
      homePagePath = "/test-center/attendance";
      break;
    case FINANCIAL_ADMIN:
      homePagePath = "/test-center/session-managment";
      break;
    case COMPANY_ADMIN:
      homePagePath = "/company/dashboard";
      break;
    default:
      homePagePath = "/test-center/dashboard";
  }
  return homePagePath;
};
