import { useContext } from 'react';
import PropTypes from 'prop-types';
import AuthContext from 'src/context/AuthContext';
import Error404 from 'src/components/Errors/Error404';

const TestCenterRoute = ({ children, allowedRoles }) => {
  const { isTestCenterAdmin, currentUser } = useContext(AuthContext);
  const role = currentUser?.role;

  if (isTestCenterAdmin() && allowedRoles.includes(role)) {
    return children;
  }

  return <Error404 />;
};

export default TestCenterRoute;

TestCenterRoute.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

TestCenterRoute.defaultProps = {
  allowedRoles: [],
};
