import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from "src/context/AuthContext";
import AppLink from '../AppLink/AppLink';
import SideBarStyle from './SideBarStyle.module.scss';
import { getMenuItemsByRole } from "./MenuItems";

const PathContainsKeyword = (keywords, path) => {
  const filteredKeywords = keywords.filter((keyword) => path.includes(keyword));
  if (filteredKeywords.length) return true;
  return false;
};
const SvpTestCenterSideBar = () => {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();
  const isActive = (path = [], keywords = []) => {
    return ((path && path.includes(location.pathname)) || (keywords && PathContainsKeyword(keywords, location.pathname))) ? SideBarStyle.active : '';
  };
  const menuItems = getMenuItemsByRole(currentUser?.role);
  return (
    menuItems.map((item) => (
      <li className={isActive(item.isActivePaths, item.isActiveKeyword)} key={item.id}>
        <AppLink
          href={item.path}
          linkText={
            item.icon
              ? (
                <>
                  <FontAwesomeIcon icon={item.icon} className={item.className} />
                  <span>
                    {item.title}
                  </span>
                </>
              )
              : (
                <>
                  <i className={item.className} />
                  <span>
                    {item.title}
                  </span>
                </>
              )
}
        />
      </li>
    ))
  );
};

export default SvpTestCenterSideBar;
