import {
  useEffect, useState, createContext, useMemo,
} from "react";
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import toastify from "src/utils/toastify";
import { COMPANY_ADMIN_USER_TYPE, CENTER_ADMIN_USER_TYPE } from "src/utils/constants";
import { fetchUserData, mockCompany } from "src/api/qiwa";
import { userLogin, verifyOTP, userLogout } from "src/api/userApi";
import Loader from "src/components/shared/Loader/Loader";
import { getPhoneNumberInfo } from "src/api/phoneVerificationApi";
import { homePagePathCurrentUser } from "src/utils/auth";
import { ASSESSOR } from "src/utils/roles";
import {
  getQiwaSsoBaseUrl,
  getQiwaSsoClientId,
  getQiwaSsoCodeChallenge,
  getQiwaSsoRedirectUri,
  initConfig,
} from "src/config/config";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['loggedIn']);
  const [locale, setLocale] = useState(i18n.language);
  const [isLoading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [phoneVerificationDetails, setPhoneVerificationDetails] = useState(null);
  const [loginErrorCount, setLoginErrorCount] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const isCompanyAdmin = () => currentUser?.user_type === COMPANY_ADMIN_USER_TYPE;
  const isTestCenterAdmin = () => currentUser?.user_type === CENTER_ADMIN_USER_TYPE;
  const isAuthorized = () => currentUser;// && cookies.loggedIn === "true";
  const router = useNavigate();
  // const location = useLocation();

  const checkUserLoggedIn = () => {
    try {
      const savedUser = currentUser || JSON.parse(localStorage.getItem("user"));
      // To be revisited
      if (!savedUser) {
        setCurrentUser(null);
        // if (!LOGIN_PATHS.includes(location.pathname)) router("/");
      } else if (!currentUser) setCurrentUser(savedUser);
    } catch (err) {
      toastify("error", err.message);
    }
  };

  const phoneNumberDetails = async () => {
    localStorage.removeItem("phoneNumberInfo");
    setPhoneVerificationDetails(null);

    try {
      const { data } = await getPhoneNumberInfo();
      console.log(data);
      setPhoneVerificationDetails(data);
      localStorage.setItem("phoneNumberInfo", JSON.stringify(data));
    } catch (err) {
      toastify("error", err.message);
      router("/");
    }
  };

  const saveUser = (user, presistant = false) => {
    localStorage.removeItem('selectedWorkers');
    localStorage.removeItem('selectedCategoryId');
    localStorage.removeItem('allowedExamsNumber');
    if (presistant)localStorage.setItem('user', JSON.stringify(user));
    // setCookie("loggedIn", true, {
    //   path: "/",
    //   maxAge: 3600, // Expires after 1hr
    //   sameSite: true,
    // });
    setCurrentUser(user);
  };

  const afterSignInPath = async (role) => {
    const homePagePath = homePagePathCurrentUser(role);
    router(homePagePath);
  };
  const loginAsTestCenter = async (data) => {
    try {
      const response = await userLogin(data);
      const userData = response.data;
      if (userData.role !== ASSESSOR) {
        phoneNumberDetails();
      }
      if (userData.two_factor_authentication_activated) {
        saveUser(userData, false);
        router("/test-center/otp");
        // setCookie("loggedIn", false, {
        //   path: "/",
        //   maxAge: 3600, // Expires after 1hr
        //   sameSite: true,
        // });
      } else {
        saveUser(userData, true);
        afterSignInPath(userData.role);
        // setCookie("loggedIn", true, {
        //   path: "/",
        //   maxAge: 3600, // Expires after 1hr
        //   sameSite: true,
        // });
      }
    } catch (err) {
      const message = err?.message || err?.error;
      setLoginErrorCount(loginErrorCount + 1);
      toastify("error", message);
    }
  };

  const loginAsCompanyAdmin = async (data) => {
    try {
      const response = await fetchUserData(data);
      const userData = response.data;
      saveUser(userData, false);
      router("/workspaces");
    } catch (err) {
      toastify("error", err.message);
      router("/");
    }
  };

  const mockCompanyLogin = async (identity_number) => {
    try {
      setCookie('qiwa.authorization', "SSO_MOCK");
      const response = await mockCompany(identity_number);
      const userData = response.data;
      saveUser(userData, false);
      // setCookie("loggedIn", false, {
      //   path: "/",
      //   maxAge: 3600, // Expires after 1hr
      //   sameSite: true,
      // });
      router("/workspaces");
    } catch (err) {
      toastify("error", err.message);
      router("/");
    }
  };

  const verifyTcOTP = async (data) => {
    try {
      const response = await verifyOTP(data);
      const userData = response.data;
      afterSignInPath(userData.role);
      saveUser(userData, true);
      localStorage.setItem("user", JSON.stringify(userData));
    } catch (err) {
      toastify("error", err.message);
    }
    setLoading(false);
  };

  const clearUserDataAfterLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("phoneNumberInfo");
    localStorage.removeItem('selectedWorkers');
    localStorage.removeItem('selectedCategoryId');
    sessionStorage.removeItem('testCycleActiveSteps');
    sessionStorage.removeItem('testCycleTestTakerInfo');
    sessionStorage.removeItem('testCycleExamInfo');
    sessionStorage.removeItem('testCycleExamUrl');
    sessionStorage.removeItem('phoneVerificationModalShown');
    sessionStorage.removeItem("phoneNumberUpdated");
    setCurrentUser(null);
    setPhoneVerificationDetails(null);
    removeCookie("loggedIn");
    removeCookie("qiwa.authorization");
  };

  const logout = async (lang) => {
    try {
      await userLogout({ lang, qiwa_token: cookies["qiwa.authorization"] });
      clearUserDataAfterLogout();
      router("/");
    } catch (err) {
      toastify("error", err.message);
    }
    setLoading(false);
  };

  const generateCompanySignInUrl = () => {
    const params = {
      client_id: getQiwaSsoClientId(),
      redirect_uri: getQiwaSsoRedirectUri(),
      response_type: "code",
      scope: "openid email phone profile",
      code_challenge: getQiwaSsoCodeChallenge(),
      code_challenge_method: "S256",
    };

    const paramsString = Object.keys(params).map((key) => [key, params[key]].join("=")).join("&");
    return `${getQiwaSsoBaseUrl()}/authorized?${paramsString}`;
  };

  const contextValues = useMemo(() => {
    return {
      currentUser,
      phoneVerificationDetails,
      saveUser,
      loginAsCompanyAdmin,
      loginAsTestCenter,
      logout,
      clearUserDataAfterLogout,
      verifyTcOTP,
      afterSignInPath,
      phoneNumberDetails,
      cookies,
      setCookie,
      removeCookie,
      loginErrorCount,
      setLoading,
      isAuthorized,
      isCompanyAdmin,
      isTestCenterAdmin,
      locale,
      setLocale,
      mockCompanyLogin,
      generateCompanySignInUrl,
    };
  });

  useEffect(() => {
    (async () => {
      await initConfig();
      setIsReady(true);
    })();
    checkUserLoggedIn();
  }, []);

  if (isLoading || !isReady) { return <Loader />; }

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
