import { useTranslation } from 'react-i18next';
import error404 from "src/assets/images/errors/error-404.png";
import AppLink from 'src/components/shared/AppLink/AppLink';

const Error404 = () => {
  const { t } = useTranslation('errors');
  return (
    <div className="text-center pt-5 error-pages w-100">
      <img src={error404} alt="" className="error-img" />
      <h1 className="error-title">{t('errorPages.404.title')}</h1>
      <p className="error-name">{t('errorPages.404.name')}</p>
      <span className="error-discription">{t('errorPages.404.description')}</span>
      <span className="error-discription">{t('errorPages.404.details')}</span>
      <AppLink
        href='/'
        linkText={t('errorPages.backHome')}
        moduleClassName="btn-primary"
        className="back-to-hpage"
      />
    </div>
  );
};

export default Error404;
