import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
  order: ['localStorage'],
  lookupLocalStorage: 'lng',
  caches: ['localStorage'],
  htmlTag: document.documentElement,
};

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: ["ar", "en"],
    whitelist: ["ar", "en"],
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: options,
  });
