/* eslint-disable no-useless-escape  */
export const EN_REGEX = /^[a-zA-Z\(\)\s]+$/;
/* eslint-disable no-useless-escape  */
export const AR_REGEX = /[\u0600-\u06FF\s\u0621-\u064A\u0660-\u0669\u066E-\u06D3\u06D5-\u06DC\u06DE-\u06FF]+$/;
export const DIGITS_REGEX = /^\d+$/;
export const MOBILE_REGEX = /(^(05)[0-9]{8}|^(\+966|00966)[0-9]{9})/;
export const EMAIL_REGEX = /[-a-zA-Z0-9_+}{\'?]+(\.[-a-zA-Z0-9_+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.([a-zA-Z]{2,})|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?/;
export const WEB_LINK_REGEX = /^(?:http(s)?:\/\/)?(?!:\/\/)([a-zA-Z0-9\-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9\-_]+\.[a-zA-Z]{2,11}?[\w\-\._~:\/?#\[\]@!\$&'\(\)\*\+,;=.]+$/;
export const LANDLINE_MOBILE_REGEX = /^(^(05|01)[0-9]{8}|^(\+966|00966)[0-9]{9})+$/;
export const BUILDING_NUMBER_REGEX = /^[a-zA-Z0-9_@.#&+\-\p{Arabic}\s]+$/;
export const SPECIAL_CHAR_REGEX = /[%٪]/;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,16}$/;
