export const TESTCENTER_ADMIN = "TestCenterAdmin";
export const CENTERBRANCH_ADMIN = "CenterBranchAdmin";
export const COMPANY_ADMIN = "CompanyAdmin";
export const ASSESSOR = "Assessor";
export const SUPERVISOR = "Supervisor";
export const RECEPTIONIST = "Receptionist";
export const FINANCIAL_ADMIN = "FinancialAdmin";
export const DASHBOARD_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN,
  SUPERVISOR];
export const SESSION_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN,
  SUPERVISOR];
export const SESSION_VIEW_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN,
  SUPERVISOR, FINANCIAL_ADMIN];
export const SESSION_MANAGEMENT_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN,
  SUPERVISOR, FINANCIAL_ADMIN];
export const UPCOMING_EXAMS_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN, SUPERVISOR];
export const TEST_CYCLE_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN, ASSESSOR, SUPERVISOR];
export const PAYABLE_INVOICE_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN, FINANCIAL_ADMIN];
export const PROFILE_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN,
  SUPERVISOR];
export const ATTENDANCE_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN, RECEPTIONIST];
export const MANAGE_BRANCHES_ROLES = [TESTCENTER_ADMIN, CENTERBRANCH_ADMIN];
