import { getSSOContext } from "src/config/config";
import axiosClient from "./apiClient";

export const autheniticateQiwaUser = async () => {
  try {
    const response = await fetch(getSSOContext(), { method: 'get', credentials: 'include' });
    return await response.json();
  } catch (err) {
    return { error: err?.response?.data?.error };
  }
};

export const fetchUserData = (data) => {
  return axiosClient.post("/auth/login", data);
};

export const listWorkspaces = (data) => {
  return axiosClient.get("/workspaces", data);
};

export const selectWorkspace = (data) => {
  return axiosClient.put(`/workspaces/${data.cp_id}`, data);
};

export const mockCompany = (identity_number) => {
  return axiosClient.post(`/auth/login?identity_number=${identity_number}`);
};
