import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import AppButton from '../shared/AppButton/AppButton';
import LinkStyle from '../shared/AppLink/AppLinkStyle.module.scss';
import FormikInput from '../shared/Form/Input/FormikInput';

const UpdatePhone = () => {
  const { t } = useTranslation(['errors', 'profile', 'tc', 'common']);
  const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(true);
  return (
    <>
      <p className='text-black font-size-16 font-light'>
        {t('tc:current_number_notice')}
      </p>
      <p className='text-black font-size-16 font-light'>
        {t('tc:otp_number_notice')}
      </p>
      <div>
        (
        <FormikInput
          id="phone_number"
          name='phone_number'
          moduleClassName='general-input'
          containerClassName="d-inline-block w-60"
          inputGlobalClassName="w-100 d-inline-block with-plus"
          disabled={phoneNumberDisabled}
        />
        <AppButton
          text={<FontAwesomeIcon icon={faPencil} />}
          variant='transparent'
          className={`${LinkStyle['btn-primary']} ${LinkStyle['vertical-top']} py-1 px-2 mx-2`}
          onClick={() => setPhoneNumberDisabled(!phoneNumberDisabled)}
        />

        );

      </div>
    </>
  );
};
export default UpdatePhone;
