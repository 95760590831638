import React, { useContext, useEffect } from "react";
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import authContext from "src/context/AuthContext";
import LoginAppFooter from "src/components/shared/AppFooter/LoginAppFooter";
import AppFooter from "src/components/shared/AppFooter/AppFooter";
import AppNavBar from "src/components/shared/AppNavbar/AppNavbar";
import AppSideBar from "src/components/shared/SideBar/SideBar";
import { NoHeaderPATHS } from "src/utils/constants";
import { homePagePathCurrentUser } from "src/utils/auth";
import PhoneVerificationModal from 'src/components/PhoneVerification/PhoneVerificationModal';
import toastify from "src/utils/toastify";
import LayoutStyle from "./LayoutStyle.module.scss";

const TcLayout = ({ children, showSideBar }) => {
  const location = useLocation();
  const {
    logout, currentUser, phoneVerificationDetails,
  } = useContext(authContext);

  const {
    full_name: itemName, role: itemRole, organization_name: organization,

  } = currentUser;
  const homePagePath = homePagePathCurrentUser(itemRole);
  const phoneNumberInfo = JSON.parse(localStorage.getItem("phoneNumberInfo")) || phoneVerificationDetails;
  const itemType = 'test_center';
  const loggedIn = !NoHeaderPATHS.includes(location.pathname);
  const phoneVerificationModalShown = JSON.parse(sessionStorage.getItem("phoneVerificationModalShown"));
  const canUserVerifyPhoneNumber = (currentUser
  && !!phoneNumberInfo
  && phoneNumberInfo?.is_phonenumber_verified === false
  && (location.pathname === homePagePath));
  const checkToast = () => {
    if (localStorage.getItem("ToastMessage")) {
      const message = localStorage.getItem("ToastMessage");
      localStorage.removeItem('ToastMessage');
      toastify('error', message);
    }
  };

  useEffect(() => {
    checkToast();
  }, []);
  return (
    <>
      {/* && cookies?.loggedIn === "true" */}
      { loggedIn
      && (
      <AppNavBar
        loginData={{
          itemName, itemRole, organization, itemType,
        }}
        loggedIn
        logout={logout}
        className={location.pathname === '/' && "landing-navbar"}
      />
      )}

      <>
        <main className={`${!loggedIn ? LayoutStyle["tc-login-path"] : LayoutStyle["tc-app"]} ${itemType === 'test_center' ? LayoutStyle["loggin-footer"] : LayoutStyle["not-loggin-footer"]} ${location.pathname === '/' && "landing-main"}`}>
          <div className={showSideBar ? 'page-content' : ''}>
            {/* && cookies?.loggedIn === "true" */}
            { loggedIn && showSideBar && <AppSideBar />}
            {children}
            {
        canUserVerifyPhoneNumber
        && phoneNumberInfo?.is_subaccount === false
        && (<PhoneVerificationModal phoneNumber={phoneNumberInfo?.phone_number} showModal />)
      }

            {
            canUserVerifyPhoneNumber
            && phoneNumberInfo?.is_subaccount === true
            && !phoneVerificationModalShown === true
            && (<PhoneVerificationModal phoneNumber={phoneNumberInfo?.phone_number} showModal />)
          }
          </div>
          {' '}
          {
            !loggedIn && <LoginAppFooter />
          }
        </main>
        {
          loggedIn && <AppFooter />
        }
      </>
    </>
  );
};

TcLayout.propTypes = {
  children: PropTypes.node.isRequired,
  showSideBar: PropTypes.bool.isRequired,
};

export default TcLayout;
