import { useContext, useState } from "react";
import { Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import mlsdLogoAr from "src/assets/images/logos/mlsd_logo_ar.svg";
import mlsdLogoEn from "src/assets/images/logos/mlsd_logo_en.svg";
import takamolLogo from "src/assets/images/logos/takamol-logo.png";
import pacc from "src/assets/images/logos/svpLogo.png";
import FormikInput from 'src/components/shared/Form/Input/FormikInput';
import LinkStyle from 'src/components/shared/AppLink/AppLinkStyle.module.scss';
import authContext from "src/context/AuthContext";
import { getShowMockSSO } from "src/config/config";
import AppFooterStyle from "./AppFooterStyle.module.scss";
import AppLink from "../AppLink/AppLink";
import AppButton from "../AppButton/AppButton";

const AppFooter = () => {
  const { t } = useTranslation('footer');
  const handelScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  const { mockCompanyLogin, isAuthorized } = useContext(authContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const showMockSSO = () => getShowMockSSO() === "yes";
  const initialValues = {
    identity_number: '',
  };
  const onSubmit = (values) => {
    mockCompanyLogin(values.identity_number);
  };
  return (
    <div className={`${AppFooterStyle.footer}`}>
      <Container className={`${AppFooterStyle.mainFooter}`} fluid>
        <Row className="justify-content-between">
          <div className={`${AppFooterStyle['inner-content']} d-inline-block w-auto ms-3 align-self-center`}>
            <p className={`${AppFooterStyle["footer-title"]}`}>{t('footer:pacc')}</p>
            <AppLink
              linkText={t('footer:about_us')}
              href="/"
              moduleClassName="light-link"
              onClick={handelScrollToTop}
            />
          </div>
          <div className={`${AppFooterStyle['inner-content']} d-inline-block w-auto`}>
            <ul className={`${AppFooterStyle.footerLogos}`}>
              <li>
                {
                  document.documentElement.lang === ('ar') && <AppLink linkText={<img src={mlsdLogoAr} alt="" />} href="https://mlsd.gov.sa/ar" />
                }
                {
                  document.documentElement.lang === ('en') && <AppLink linkText={<img src={mlsdLogoEn} alt="" />} href="https://mlsd.gov.sa/ar" />
                }
              </li>
              <li>
                <AppLink linkText={<img src={takamolLogo} alt="" />} href="https://takamolholding.com/ar" />
              </li>
            </ul>
          </div>
        </Row>
      </Container>
      <Container className={`${AppFooterStyle.smallFooter}`} fluid>
        <Row className="justify-content-between">
          <div className="d-inline-block w-auto ms-3 align-self-center">
            <AppLink linkText={<img src={pacc} alt="" />} href="/" className={`${AppFooterStyle.paccLogo}`} />
            <ul className={`${AppFooterStyle.footerLinks}`}>
              <li>
                <AppLink
                  linkText={t('footer:privacy_policy')}
                  href="/terms-of-service/privacy-policy"
                  moduleClassName="light-link"
                  className="gray-link"
                />
              </li>
              <li>
                <AppLink
                  linkText={t('footer:terms')}
                  href="/terms-of-service/terms"
                  moduleClassName="light-link"
                  className="gray-link"
                />
              </li>
              <li>
                <AppLink
                  linkText={t('footer:knowledge_center')}
                  href="/knowledge-center"
                  moduleClassName="light-link"
                  className="gray-link"
                />
              </li>
              <li>
                {!isAuthorized() && showMockSSO()
                && (
                <AppButton
                  className="gray-link pt-0 mt-0 border-none"
                  text="Mock Company Login"
                  onClick={handleShow}
                  variant="transparent"
                />
                )}
              </li>
              <li>
                <p>
                  <span>
                    {getCurrentYear()}
                    /
                  </span>
                  <i>
                    &copy;
                  </i>
                  <span>
                    {t('footer:pacc_program')}
                  </span>
                </p>
              </li>
            </ul>
            {/* <ToggleLanguage /> */}
          </div>
          <div className="d-inline-block w-auto">
            <ul className={`${AppFooterStyle.footerSocial}`}>
              <li>
                <AppLink
                  linkText="920024540"
                  href="/"
                  moduleClassName="light-link"
                  className="gray-link"
                />
              </li>
              <li>
                <AppLink
                  linkText={<FontAwesomeIcon icon={faTwitter} />}
                  href="https://twitter.com/SaudiPACC"
                  moduleClassName="light-link"
                  className="gray-link"
                  target="_blank"
                />
              </li>
              <li>
                <AppLink
                  linkText={<FontAwesomeIcon icon={faLinkedinIn} />}
                  href="https://www.linkedin.com/company/professional-accreditation/posts/?feedView=all"
                  moduleClassName="light-link"
                  target="_blank"
                />
              </li>
              <li>
                <AppLink
                  linkText={<FontAwesomeIcon icon={faFacebookF} />}
                  href="https://www.facebook.com/SaudiPACC"
                  moduleClassName="light-link"
                  target="_blank"
                />
              </li>
            </ul>
          </div>
        </Row>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Mock Company Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
          >
            {({
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormikInput
                  placeholder="identity_number"
                  id="identity_number"
                  name='identity_number'
                />

                <Modal.Footer className="d-flex justify-content-end border-none mt-3">
                  <AppButton
                    onClick={handleClose}
                    variant="transparent"
                    text="cancel"
                    size="lg"
                    className={`${LinkStyle['btn-primary-outline']} ${LinkStyle['btn-md']}`}
                  />
                  <AppButton
                    text="Mock"
                    size="lg"
                    type="submit"
                    variant='transparent'
                    className={`${LinkStyle['btn-primary']} ${LinkStyle['btn-md']}`}
                  />
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AppFooter;
