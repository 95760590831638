import { toast } from "react-toastify";

/**
 *
 * @param {string} status info | error | success | warning
 * @param {string} message
 * @param {*} options
 */
const toastify = (status, message, options = {}) => {
  try {
    toast.dismiss();
  } catch (e) {
    // console.error(e);
  }
  toast[status](message, { ...options });
};

export default toastify;
