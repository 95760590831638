/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from 'prop-types';
import { useLocation } from "react-router-dom";
import AppFooter from "src/components/shared/AppFooter/AppFooter";
import LoginAppFooter from "src/components/shared/AppFooter/LoginAppFooter";
import { LOGIN_PATHS, NoHeaderPATHS } from "src/utils/constants";
import GuestNavbar from "src/components/shared/AppNavbar/GuestNavbar";
import LayoutStyle from "./LayoutStyle.module.scss";

const GuestLayout = ({ children }) => {
  const location = useLocation();
  const logInPath = LOGIN_PATHS.includes(location.pathname);
  const loggedIn = !NoHeaderPATHS.includes(location.pathname);
  return (
    <div>
      {logInPath ? (
        <main className={!loggedIn ? LayoutStyle["tc-login-path"] : LayoutStyle["tc-app"]}>
          <div className={`${LayoutStyle['tc-login-path-container']}`}>
            {children}
          </div>
          <LoginAppFooter />
        </main>
      )
        : location.pathname === '/'
          ? (
            <>

              <main className="landing-main">{children}</main>
              <GuestNavbar className="landing-navbar" />
              <AppFooter />
            </>
          )
          : (
            <>

              <main>{children}</main>
              <GuestNavbar />
              <AppFooter />
            </>

          )}
    </div>
  );
};

GuestLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestLayout;
