import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import AppLinkStyle from './AppLinkStyle.module.scss';

const AppLink = (props) => {
  const {
    linkText,
    className,
    href,
    moduleClassName,
    title,
    disabled,
    onClick,
    ...rest
  } = props;
  return (
    <NavLink
      onClick={onClick}
      to={href}
      className={`${className} ${AppLinkStyle[moduleClassName]} ${AppLinkStyle.btn} ${disabled && AppLinkStyle["disabled-btn"]}`}
      title={title}
      {...rest}
    >
      {linkText}
    </NavLink>
  );
};

AppLink.propTypes = {
  linkText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  moduleClassName: PropTypes.string,
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
AppLink.defaultProps = {
  className: '',
  title: '',
  moduleClassName: '',
  disabled: false,
  onClick: null,
};

export default AppLink;
