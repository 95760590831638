import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import { NavDropdown, Dropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';
import authContext from 'src/context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import SVPLogo from 'src/assets/images/logos/svpLogo.png';
import userIcon from 'src/assets/images/userIcon.svg';
import bronze from 'src/assets/images/companyCertificate/bronze.png';
import silver from 'src/assets/images/companyCertificate/silver.png';
import gold from 'src/assets/images/companyCertificate/gold.png';
import enLang from 'src/assets/images/logos/uk.png';
import arLang from 'src/assets/images/logos/saudi.png';
import AppNotification from 'src/components/shared/AppNotification/AppNotification';
import AppButton from 'src/components/shared/AppButton/AppButton';
import AppLink from 'src/components/shared/AppLink/AppLink';
import { useLocation } from "react-router-dom";
import AppNavbarStyle from './AppNavbarStyle.module.scss';
import linkStyle from '../AppLink/AppLinkStyle.module.scss';

const AppNavbar = ({
  className, loginData, loggedIn, logout, switchWorkspace, showCompany = true,
}) => {
  const { t, i18n } = useTranslation('common');
  const location = useLocation();
  const shownGoTOHomePage = !(location.pathname.includes('test-center')
   || location.pathname.includes('company')
   || location.pathname.includes('workspaces'));
  const isLoggedInCompany = loggedIn && loginData.itemType === 'company';
  const isLoggedInTestCenter = loggedIn && loginData.itemType !== 'company';
  const { locale, setLocale, afterSignInPath } = useContext(authContext);
  const changeLocale = (l) => {
    if (locale !== l) {
      i18n.changeLanguage(l);
      setLocale(l);
      window.location.reload(true);
    }
  };
  const goToHomePage = async () => {
    await afterSignInPath(loginData?.itemRole);
  };

  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });

  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar collapseOnSelect expanded={expanded} expand='lg' bg="white" variant="white" fixed="top" className={`${AppNavbarStyle.appMainNavbar} ${AppNavbarStyle[className]} ${navbar && AppNavbarStyle.white} px-3`}>
      <Navbar.Brand href={location.pathname.includes('workspaces') ? '' : '/'}>
        <img src={SVPLogo} alt="" width={180} className={`${AppNavbarStyle['logo-img']} mx-3`} />
      </Navbar.Brand>
      {(!shownGoTOHomePage) && (
      <Navbar.Text className={`${AppNavbarStyle.navbarTitle}`}>
        {showCompany && loginData.organization}
        <span>
          {loginData.itemType === 'company' && showCompany && `(${loginData?.company?.cp_id})`}
        </span>
      </Navbar.Text>
      )}
      {
      loginData.itemType === 'company' && (
        <>
          {loginData.itemCertificate === 'bronze' && (
            <Nav.Link href="/"><img src={bronze} alt="" /></Nav.Link>
          )}
          {loginData.itemCertificate === 'gold' && (
            <Nav.Link href="/"><img src={gold} alt="" /></Nav.Link>
          )}
          {loginData.itemCertificate === 'silver' && (
            <Nav.Link href="/"><img src={silver} alt="" /></Nav.Link>
          )}
        </>
      )
    }
      <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} className={`${expanded ? 'show-btn' : ''}`} aria-controls='responsive-navbar-nav'>
        <FontAwesomeIcon icon={faBars} className={`${AppNavbarStyle['open-menu']} ${expanded ? 'd-none' : ''} open-menu`} />
        <FontAwesomeIcon icon={faTimes} className={`${expanded ? '' : 'd-none'} close-menu`} />
      </Navbar.Toggle>
      <Navbar.Collapse id='responsive-navbar-nav' className="justify-content-end menu-margin">
        {
          (shownGoTOHomePage && (
          <>
            <AppLink
              linkText={t('common:discover')}
              href="/journey"
              moduleClassName="light-link"
              className={`${AppNavbarStyle['menu-link']} black-color mx-2 discover-page`}
              onClick={() => setExpanded(false)}
            />
            <AppLink
              linkText={t('common:knowledge_center')}
              href="/knowledge-center"
              moduleClassName="light-link"
              className={`${AppNavbarStyle['menu-link']} black-color mx-2 discover-page`}
              onClick={() => setExpanded(false)}
            />
            <Dropdown>
              <Dropdown.Toggle variant="transparnt" className={`${AppNavbarStyle['check-menu']} black-color mx-2 font-light`}>
                {t('common:check')}
              </Dropdown.Toggle>

              <Dropdown.Menu className='register_check_dd'>
                <Dropdown.Item href="/worker-eligibilty" className={`${AppNavbarStyle['check-menu-item']} text-start py-2`}>
                  {t('common:eligibility_of_worker')}
                </Dropdown.Item>
                <Dropdown.Item href="#/action-1" className={`${AppNavbarStyle['check-menu-item']} d-none py-2`}>
                  {t('common:eligibility_of_company')}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <AppButton
              onClick={() => {
                goToHomePage();
                setExpanded(false);
              }}
              text={t('common:go_to_dashboard')}
              moduleClassName="btn-primary"
              className={`${linkStyle['btn-md']} font-light font-size-16 mx-2 px-3 my-md-2`}
            />
          </>
          )
          )
        }
        {location.pathname !== '/' && <AppNotification />}
        <div className='my-md-2 my-sm-2'>
          <img src={userIcon} alt="" className='d-inline-block  align-text-top' />
          <NavDropdown
            className={`${AppNavbarStyle.userMenuDD} d-inline-block px-md-2`}
            title={(
              <div className={`${AppNavbarStyle['menu-link']} d-flex `}>
                <div>
                  <span>{loginData.itemName}</span>
                  <span>{loginData?.itemRole && t(`${loginData.itemRole}`)}</span>
                </div>
                <div className={`${AppNavbarStyle['menu-icon']} mx-2`}>
                  <FontAwesomeIcon icon={faEllipsisVertical} className='mx-2' />
                </div>
              </div>
            )}
            id="collasible-nav-dropdown"
          >
            {
              isLoggedInCompany && <NavDropdown.Item href="#action/3.1" onClick={() => { switchWorkspace({ }); }} className={`${AppNavbarStyle.userMenuItem}`}>{t('switchAccount')}</NavDropdown.Item>
            }
            {
              isLoggedInTestCenter && (
                <NavDropdown.Item href="/change-password" className={`${AppNavbarStyle.userMenuItem}`}>{t('changePassword')}</NavDropdown.Item>
              )
            }
            <NavDropdown.Item onClick={() => { logout({}); }} className={`${AppNavbarStyle.userMenuItem}`}>{t('logout')}</NavDropdown.Item>
          </NavDropdown>
        </div>
        <NavDropdown
          title={locale === 'ar'
            ? (
              <span>
                عربى
                <img src={arLang} alt="" className='mx-2 header-lang' />
              </span>
            )
            : (
              <span>
                English
                <img src={enLang} alt="" className='mx-2 header-lang' />
              </span>
            )}
          className={`${AppNavbarStyle['lang-dropdown']} lang-dropdown`}
        >
          <NavDropdown.Item href="" onClick={() => { changeLocale('en'); }} className={`${AppNavbarStyle['lang-link']} ${locale === ('en') ? `${AppNavbarStyle['active-lang']}` : ''}`}>
            English
            <img src={enLang} alt="" className='mx-2 header-lang' />
          </NavDropdown.Item>
          <NavDropdown.Item href="" onClick={() => { changeLocale('ar'); }} className={`${AppNavbarStyle['lang-link']} ${locale === ('ar') ? `${AppNavbarStyle['active-lang']}` : ''}`}>
            العربية
            <img src={arLang} alt="" className='mx-2 header-lang' />
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};

AppNavbar.propTypes = {
  className: PropTypes.string,
  loginData: PropTypes.shape({
    organization: PropTypes.string,
    company: PropTypes.shape({
      cp_id: PropTypes.string,
    }),
    itemType: PropTypes.string,
    itemCertificate: PropTypes.string,
    itemName: PropTypes.string,
    itemId: PropTypes.string,
    itemRole: PropTypes.string,
  }),
  loggedIn: PropTypes.bool,
  logout: PropTypes.func,
  switchWorkspace: PropTypes.func,
  showCompany: PropTypes.bool,
};

AppNavbar.defaultProps = {
  className: "",
  loginData: { organization: '', company: { cp_id: '' } },
  loggedIn: true,
  showCompany: true,
  logout: (() => {}),
  switchWorkspace: () => {},
};

export default AppNavbar;
